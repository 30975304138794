import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import ContactSlider from "../zenix/Home/ContactSlider";
import IndexTab from "../zenix/Home/IndexTab";
import Layout from "template";
import { MenuItems } from "template/MenuItems";
import { Timeline } from 'react-twitter-widgets'


//Images
import Card1 from './../../images/card/card1.jpg';
import Card2 from './../../images/card/card2.jpg';
import Card3 from './../../images/card/card3.jpg';
import Card4 from './../../images/card/card4.jpg';

import ehtIcon from  '../../assets/images/rent/EtheColor_icon.svg';
import rentIcon from '../../assets/images/rent/RentSimboloColor_icon.svg';
import btcIcon from  '../../assets/images/rent/BitcoinColor_icon.svg';
import {ApexBar3} from '../zenix/apexCharts/Bar3';
import {Grahp3} from '../zenix/apexCharts/Grahp3';


// const MarketOverviewChart = loadable(() =>
// 	pMinDelay(import("../zenix/apexCharts/Bar3"), 1000)
// );

const cardBox = [
	{image: Card1},
	{image: Card2},
	{image: Card3},
	{image: Card4},
];

const orderdataBlog = [
	{price: '82.1', amount: '58.9', total: '134.10',},
	{price: '85.2', amount: '55.8', total: '136,12',},
	{price: '87.3', amount: '53.7', total: '138,12',},
	{price: '89.4', amount: '51.6', total: '139,12',},
	{price: '91.9', amount: '47.1', total: '140,12',},
	{price: '93.8', amount: '46.2', total: '142,12',},
	{price: '94.7', amount: '45.3', total: '145,12',},
	{price: '97.6', amount: '44.4', total: '147,12',},
];

export const Dashboard: React.FC = () => {
	const [crrency1, setCrrency1] = useState("Monthly (2021)");
	const [country1, setCountry1] = useState("Medan, IDN");
	

		  return (
    		<Layout title="Titulo"  icon="icon_DashboardColor_icon w-30" backlink={"#"} >
			<Fragment>
			<div className="container-fluid pt-body">
				<div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
					<h2 className="font-w600 mr-auto "> 
					<span className="mm-active">
						<div className="personalizedicon"> <div className="icon_DashboardColor_icon w-30"></div> </div> 
					</span> 
            			Dashboard 
					</h2>
					<div className="weather-btn "></div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-sm-6 m-t35">
						<div className="card">
							<div className="card-header border-0 flex-wrap  select-btn">
								<div className="mb-3">
									<h4 className="fs-18">Inversiones</h4>
									<p className="mb-0 fs-12 ">LLC’s, participaciones y contratos</p>
								</div>
							</div>
							<div className="card-body">
								<Grahp3 />
							</div>
						</div>
					</div>
					<div className="col-xl-8 col-sm-6 m-t35">
						<div className="card">
							<div className="card-header border-0 flex-wrap  select-btn">
								<div className="mb-3">
									<h4 className="fs-18 ">Inversiones</h4>
									<p className="mb-0 fs-12 ">Inviersión (USDC), rentabilidad (Rent y USDC), retorno de inversión (ROI), disponible retiro</p>
								</div>
							</div>
							<div className="card-body ">
								<ApexBar3 />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-xxl-8">
						<div className="card">
							<div className="card-header border-0 flex-wrap  select-btn">
								<div>
									<h4 className="fs-18 ">Noticias</h4>
								</div>
							</div>
							<div className="card-body">
							{/* <Timeline
							dataSource={{
								sourceType: 'profile',
								screenName: 'gnesisinc'
							}}
							options={{
								height: '400'
							}}
							/> */}

							</div>
						</div>
					</div>
					<div className="col-xl-8 col-xxl-4">
						<div className="card">
							<div className="card-header border-0 flex-wrap  select-btn">
								<div className="mb-3">
									<h4 className="fs-18 ">Comisiones</h4>
									<p className="mb-0 fs-12 "># referidos, invertido por referidos, comisión total ganada, comisión disponible</p>
								</div>
							</div>
							<div className="card-body ">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>Usuario</th>
											<th>Total Comprado</th>
											<th>Total Comisión</th>
											<th>Estado</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td  colSpan={4} >
												<div className="alert alert-info">
													Tus afiliados no han realizado compras
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				</div>
			</Fragment>
      </Layout>
		)
	
}
export {orderdataBlog};
// export default Dashboard;