import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { userRegister } from "services/authService";
import { userUpdate } from "services/usersService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import logo from '../assets/images/rent/RentLogoBlack_icon.svg';
import {WindowModal} from '../users/modal';


const ListadoReferidos: React.FC<{lista:string}> = ({lista}) => {


  const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
//   const listaElementos = JSON.parse(lista);
  const listaElementos = lista ? JSON.parse(lista): [{'av':'https://mir-s3-cdn-cf.behance.net/project_modules/disp/ce54bf11889067.562541ef7cde4.png','name':'No tienes Referidos =(','t':'0'}];
  

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
        iconForm:{
            marginRight: '10px',
        },
        formRegister:{
            width:'85%',
        },
        colorSVG:{
            color:'#FFFFFF',
        }
    };
    

    console.log(lista)

    return (
        <>
            <div className="row m-t-30">
            {
                listaElementos?.map((event) => (
                    <div className="col-md-3">
                        <div className="d-flex">

                            <img src={event.av} alt="logo" className="w80 d-flex" />
                            <div className="m-l-10">
                                <p className="w-100 m-b-0">{event.name}</p>
                                <p className="w-100 fs-13 text-rent"><em>{event.t} franquicias adquiridas.</em></p>
                            </div>
                        </div>
                    </div>
                )
                )
            }
            </div>
        </>
                                        

    );
};

export default ListadoReferidos;

