import { APIService } from "services";
import axios from "axios"

export const getFranchises = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllFranchises(pageNo,pageSize);
        console.log('get all', res);
    }
    else{
        try {
            console.log("buscando...");
            res = await searchFranchises(search,pageNo,pageSize);
            console.log("-", res);
        } catch(err) {
            console.log('error en el query ');
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
        return res.data;
    }else{
        return { records:[], totalCount:0 }
    }
    
}


export const addFranchises = (data) => {
return APIService.api().post(`/franchises`,data)
}
export const updateFranchises = (id,data) => {
return APIService.api().patch(`/franchises/${id}`,data)
}
export const getAllFranchises = (pageNo,pageSize) => {
return APIService.api().get(`/franchises/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneFranchises = (id) => {
return APIService.api().get(`/franchises/${id}`)
}
export const searchFranchises = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/franchises/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteFranchises = (id) => {
return APIService.api().delete(`/franchises/${id}`)
}
export const getCurrency  = async () => {

    const res = await axios.get('https://www.floatrates.com/daily/cop.json', { params: { } });
    return res.data;
}
export const getPurchaseStatus  = async (id) => {

    const res = await axios.get('https://sandbox.wompi.co/v1/transactions/'+id, { params: { } });
    return res.data;
}
