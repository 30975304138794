import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setFranchisesMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addFranchises, updateFranchises } from "services/franchisesService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const FranchisesForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {

    

    const dispatch = useAppDispatch();
    const iValue={id:'',name:'',country:'',city:'',description:'',rents:'',pricing_list:'',discounts:'',min_invest:'',max_invest:'',expexted_profit:'',franchise_type:'',created_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateFranchises(values.id,values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setFranchisesMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setFranchisesMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addFranchises(values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setFranchisesMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setFranchisesMessage("Some error occured!"));
                }
            }
        },
validationSchema: yup.object({
name: yup.string().required('name is required'),
country: yup.string().required('country is required'),
city: yup.string().required('city is required'),
description: yup.string().required('description is required'),
rents: yup.string().required('rents is required'),
pricing_list: yup.string().required('pricing_list is required'),
discounts: yup.string().required('discounts is required'),
min_invest: yup.number().required('min_invest is required'),
max_invest: yup.number().required('max_invest is required'),
expexted_profit: yup.string().required('expexted_profit is required'),
franchise_type: yup.string().required('franchise_type is required'),
created_at: yup.date().required('created_at is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Franchises
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Franchises</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">name</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">country</label>
<Form.Control type="text" name="country" className="form-control" value={formik.values.country}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.country && !!formik.errors.country}
isValid ={!!formik.touched.country && !formik.errors.country}
></Form.Control>
{
    formik.errors.country && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.country}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">city</label>
<Form.Control type="text" name="city" className="form-control" value={formik.values.city}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.city && !!formik.errors.city}
isValid ={!!formik.touched.city && !formik.errors.city}
></Form.Control>
{
    formik.errors.city && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.city}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">description</label>
<Form.Control type="text" name="description" className="form-control" value={formik.values.description}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.description && !!formik.errors.description}
isValid ={!!formik.touched.description && !formik.errors.description}
></Form.Control>
{
    formik.errors.description && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.description}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">rents</label>
<Form.Control type="text" name="rents" className="form-control" value={formik.values.rents}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.rents && !!formik.errors.rents}
isValid ={!!formik.touched.rents && !formik.errors.rents}
></Form.Control>
{
    formik.errors.rents && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.rents}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">pricing_list</label>
<Form.Control type="text" name="pricing_list" className="form-control" value={formik.values.pricing_list}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.pricing_list && !!formik.errors.pricing_list}
isValid ={!!formik.touched.pricing_list && !formik.errors.pricing_list}
></Form.Control>
{
    formik.errors.pricing_list && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.pricing_list}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">discounts</label>
<Form.Control type="text" name="discounts" className="form-control" value={formik.values.discounts}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.discounts && !!formik.errors.discounts}
isValid ={!!formik.touched.discounts && !formik.errors.discounts}
></Form.Control>
{
    formik.errors.discounts && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.discounts}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">min_invest</label>
<Form.Control type="number" name="min_invest" className="form-control" value={formik.values.min_invest}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.min_invest && !!formik.errors.min_invest}
isValid ={!!formik.touched.min_invest && !formik.errors.min_invest}
></Form.Control>
{
    formik.errors.min_invest && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.min_invest}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">max_invest</label>
<Form.Control type="number" name="max_invest" className="form-control" value={formik.values.max_invest}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.max_invest && !!formik.errors.max_invest}
isValid ={!!formik.touched.max_invest && !formik.errors.max_invest}
></Form.Control>
{
    formik.errors.max_invest && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.max_invest}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">expexted_profit</label>
<Form.Control type="text" name="expexted_profit" className="form-control" value={formik.values.expexted_profit}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.expexted_profit && !!formik.errors.expexted_profit}
isValid ={!!formik.touched.expexted_profit && !formik.errors.expexted_profit}
></Form.Control>
{
    formik.errors.expexted_profit && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.expexted_profit}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">franchise_type</label>
<Form.Control type="text" name="franchise_type" className="form-control" value={formik.values.franchise_type}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.franchise_type && !!formik.errors.franchise_type}
isValid ={!!formik.touched.franchise_type && !formik.errors.franchise_type}
></Form.Control>
{
    formik.errors.franchise_type && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.franchise_type}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="date" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

