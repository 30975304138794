import React, { useState, useEffect, useContext, createContext } from "react";
import { CartDispatchContext, addToCart } from "../../contexts/cart";
import { Alert, Button, Card, Col, Form, InputGroup, Dropdown } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSelector } from "react-redux";
import { setNftsList, setNftsMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { setError } from "redux/slices/auth";
import { getOwnsNfts } from "services/nftsService"
import { addImageBanner, addImageProfile, getProfileData, passwordUpdate, closeUserAccount} from "services/usersService";
import Layout from "template";
import { Constant } from "template/Constant";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router";
// import { getFranchises_Detail } from "services/franchises_detailService";
import { getOneNfts } from "services/nftsService";
import { setFranchises_DetailList, setFranchises_DetailMessage } from "redux/actions";
import { getFranchises_Detail } from "services/franchises_detailService";
import { getOneFranchises } from "services/franchisesService";
import { Franchises_DetailForm } from "../franchises_detail/form";
import { Franchises_DetailTable } from "../franchises_detail/table";
import Parser from 'html-react-parser';
import Banner from '../../assets/images/AdobeStock_242740131_Preview.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {FinishPasswordUpdate} from '../users/finishPasswordUpdate';
import UpdateProfileForm from './updateProfileForm';
import ListadoReferidos from './listadoReferidos';
import ConfirmationModal from "template/ConfirmationModal";
import { base64 } from "ethers/lib/utils";

export const Profile: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dp = useContext(CartDispatchContext);
    let uri = location.pathname;
    let parts = uri.split("/");
    const query = parts[2];


    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          height: '540px',
          transform: 'translate(-50%, -50%)',
        },
        iconForm:{
            marginRight: '0px',
        },
        formRegister:{
            width:'85%',
        }
        
    };
       
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.nfts);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const [nft, setNft] = useState({'file': '','contact': '','description': '','author': '','owner': '','status': '','views': '','favorites': '','price': '','sale_date': '','contact_address': '','token_id': '','token_standard': '','blockchain': '','metadata': '','franchise_id': '','created_at': '','updated_at': '','deleted_at': ''});
  const [franchise, setFranchise] = useState({'id':'', 'name':'', 'description':'', 'avatar':'', 'city':'', 'country':'', 'pricing_list':'', 'discounts':'', 'min_invest':'', 'expexted_profit':'', 'qualification':'', 'google_map':'', 'rents':'', 'nft_description':''});
  const [data, setData] = useState({'id':'', 'name':'', 'price':'', 'image':''});
  const [totalFranchises, settotalFranchises] = useState(0);
  const [listReferrals, setTotalReferrals] = useState([]);
  const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
  const name = String(Object.values(userProfile)[1]);
  const [referidos, setReferidos] = useState('') ;

  const getData = (page, pageSize, searchKey) => {
        
      let query = nft.franchise_id;
      getFranchises_Detail(page, pageSize, query).then((response) => {
          if (response && response.records) {
              dispatch(setFranchises_DetailList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.totalCount, searchKey: searchKey }));
          } else {
              dispatch(setFranchises_DetailMessage("No Record Found"));
          }
      })
  }

    useEffect(() => {
    
        getProfileData().then((response) => {
            settotalFranchises(response.data.franchises);
            response.data.records.map((e) => {
                let elm = '{"av":"'+e.avatar+'","name":"'+e.name+" "+e.last_name+'","t":"'+e.t+'"}';
                setTotalReferrals(listReferrals => [...listReferrals, elm ]);
            })
            console.log(listReferrals);
        })

    },[]);

    useEffect(() => {
        setReferidos('['+listReferrals+']');
    },[listReferrals]);

    const formik = useFormik({
        initialValues: {
            password: '',
            repeatPassword: ''
        },

        onSubmit: async (values) => {
            const response = await passwordUpdate({'email': userProfile.email, 'password': values.password});
            if (response) {
                setopenModal(true)
                //navigate('/franchises', { replace: true });

            } else {
                dispatch(setError("No se pudo actualizar la clave"))
            }
        },
        validationSchema: yup.object({
            password: yup.string().trim().required('Password is required'),
            repeatPassword: yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: yup.string().oneOf(
                [yup.ref("password")],
                "Both password need to be the same"
                )
            })
        }),
    });
    const [validateResponse, setvalidateResponse] = useState('');
    const [openModal, setopenModal] = useState(false);
    const [openModalAccount, setopenModalAccount] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const handleServerDelete = async () => {
        const response = await closeUserAccount();
        if (response.status === 200) {
            setShowDelete(false);
            alert("Cuenta Cerrada Exitosamente!");
        } else {
            alert("Ocurrio un error en el sistema.");
        }
    }
    const handleCloseAccount = () => {
        setopenModalAccount(true);
        setShowDelete(true);
    }

    const [selectedImageBanner, setSelectedImageBanner] = useState<string | undefined>("");

    const handleImageChangeBanner =  (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
            const base64Image = reader.result as string;
            const response = await addImageBanner(base64Image);
            console.log("hola soy response",response);
            if (response.status === 200) {
                setSelectedImageBanner(base64Image);
            } else {
                alert("Ocurrio un error, no se pudo guardar la imagen correctamente");
            }
            };
          }
        };
        
      const [selectedImageProfile, setSelectedImageProfile] = useState<string | undefined>("");
    
      const handleImageChangeProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
              const base64Image = reader.result as string;
              const response = await addImageProfile(base64Image);
              if (response.status === 200) {
                  setSelectedImageProfile(base64Image);
              } else {
                  alert("Ocurrio un error, no se pudo guardar la imagen correctamente");
              }
              };
            }
          };

  return (
    <Layout title={"Perfil del Usuario"}  icon="icon_MisNftColor_icon w-30" backlink="#" >
        <div className="container-fluid pt-body">
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
				<h2 className="font-w600 mr-auto "> 
				    <span className="mm-active">
                        <div className="personalizedicon"> <div className="icon_MisNftColor_icon w-30"></div> </div> 
                    </span> 
                    {"Perfil del Usuario"}
				</h2>
            </div>
            <div className="row">                
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="card-body p-30">
                            <div className="row">
                                <div className="col-xl-12 col-lgs-12  col-md-12 col-xxl-12">
                                    ¡Hola {userProfile.name}!
                                    <img src={selectedImageBanner ? selectedImageBanner : Banner} className="w-100 m-t-1" style={{maxHeight: "180px"}}/>
                                    <label htmlFor="banner-upload" className="custom-file-upload">
                                        Seleccionar imagen.
                                    </label>
                                    <input id="banner-upload" type="file" accept="image/*" onChange={handleImageChangeBanner} className="custom-file-input"/>
                                </div>
                                <div className="col-xl-6 col-lgs-6  col-md-6 col-xxl-6">
                                    <div className="d-flex">
                                        <img src={selectedImageProfile ? selectedImageProfile : userProfile.avatar} alt="logo" className="w80 d-flex" style={{borderRadius: "50px"}} />
                                        <div className="m-l-10 m-t-10">
                                            <p className="w-100 m-b-0">{userProfile.name}{" "}{userProfile.last_name}</p>
                                            <p className="w-100 fs-13 text-rent"><em>{totalFranchises} franquicias adquiridas.</em></p>
                                        </div>
                                    </div>
                                    <label htmlFor="banner-upload" className="custom-file-upload-pro">
                                        Seleccionar imagen.
                                        </label>
                                        <input id="banner-upload" type="file" accept="image/*" onChange={handleImageChangeProfile} className="custom-file-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">                
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="card-body p-30">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12">
                                    <div className="m-b-20">
                                        Usuarios
                                    </div>
                                    <Tabs defaultActiveKey="referidos" id="uncontrolled-tab-example" className="mb-3" >
                                        <Tab eventKey="referidos" className="text-white" title="Referidos">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ListadoReferidos lista={referidos} />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="solicitudes" className="text-white" title="Solicitudes Para ser Referido" disabled>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {"Solicitudes Para ser Referido"}
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="otros" className="text-white" title="Otros Usuarios" disabled>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {"Otros Usuarios"}
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="comisiones" className="text-white" title="Comisionados" disabled>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {"Comisionados"}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">                
                <div className="col-lg-7 col-md-7 col-sm-7">
                    <div className="card">
                        <div className="card-body p-30">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12">
                                    Datos Personales
                                    <UpdateProfileForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-5 col-md-5 col-sm-5">
                    <div className="card">
                        <div className="card-body p-30">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12">
                                    Cambio de Contraseña

                                    <Form className="user"  onSubmit={formik.handleSubmit}>
                                        <div className="row">      
                                            <div className="col-md-12 col-sm-12 mb-3 mb-sm-0">
                                                <Form.Group>
                                                    <Form.Control 
                                                        type="hidden" 
                                                        value={userProfile.email} 
                                                        className="form-control form-control-user" 
                                                        id="email"  
                                                        name="email">

                                                        </Form.Control>
                                                </Form.Group>
                                                <Form.Group>
                                                

                                                    <div className="personalizedicon hidden-xs" style={customStyles.iconForm}>
                                                        <div className="icon_Key_icon w-30"></div>
                                                    </div>
                                                    <Form.Control type="password" className="form-control form-control-user" id="password"
                                                        value={formik.values.password}
                                                        placeholder="Contraseña"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={!!formik.touched.password && !!formik.errors.password}
                                                        isValid={!!formik.touched.password && !formik.errors.password}
                                                        style={customStyles.formRegister}
                                                    ></Form.Control>
                                                    {formik.errors.password && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.password}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 col-sm-12 mb-3 mb-sm-0">
                                            <Form.Group>
                                                <div className="personalizedicon hidden-xs pull-left" style={customStyles.iconForm}>
                                                    <div className="icon_ConfimationKey_icon w-30"></div>
                                                </div>
                                                <Form.Control type="password" className="form-control  pull-left form-control-user" id="repeatPassword"
                                                    value={formik.values.repeatPassword}
                                                    placeholder="Repeat Password"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.repeatPassword && !!formik.errors.repeatPassword}
                                                    isValid={!!formik.touched.repeatPassword && !formik.errors.repeatPassword}
                                                    style={customStyles.formRegister}
                                                ></Form.Control>
                                                {formik.errors.repeatPassword && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.repeatPassword}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            </div>

                                        </div>
                                        <div className="alertmessage">{validateResponse}</div>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 m-t-30">
                                                <button type="submit" className="btn btn-rent-invert text-white">Cambiar Contraseña</button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">                
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="card-body p-30">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12">
                                    Cierre de Cuenta
                                </div>
                                
                                <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12">
                                    <div className="comment-review star-rating d-flex m-t-10">
                                        <div className="fs-15">
                                        Para el cierre de tu cuenta de manera definitiva, debes llevar a cabo algunos 'requerimientos de cierre de cuenta (Rent S.A.S./Politicas de Usuarios Rent 2022, 2022, P.20). la cual pasará por revisión
                                        por parte del equipo Rent. En 48 horas tendrás respuesta, en caso de que haya sido posible el cierre de tu cuenta contarás con 30 dias para volver reabrirla, pasados dichos dias la única manera de
                                        poder ser usuario nuevamente de Rent será abriendo una nueva cuenta. A continuación te presentamos el listado de los requerimentos para el cierre de tu cuenta:
                                        </div>
                                    </div>
                                    <div className="comment-review star-rating d-flex m-t-10">
                                        <div className="fs-15">
                                            - Retirar las ganancias generadas por tus participaciones O venta de estas con otros usuarios (incluye al Super Administrador Rent en caso tal).
                                        </div>
                                    </div>
                                    <div>
                                        <div className="fs-15">
                                            - Concretar las ofertas que hayas enviado O recibido, sea cancelándolas en el caso que sea posible, vendiéndolas o comprándolas.
                                        </div>
                                    </div>
                                    <div>
                                        <div className="fs-15">
                                            - Vender todas tus participaciones sea a otros usuarios O al Super Administrador Rent.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12 comment-review star-rating d-flex m-t-10">
                                    <Button onClick={handleCloseAccount} className="btn-rent-invert " title="Conectar Wallet" variant="primary">
                                        Cerrar Cuenta
                                    </Button> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {openModal ? <FinishPasswordUpdate letmodal={true} />: null}
        {openModalAccount ? 
            <ConfirmationModal buttonNegative="Cancelar" buttonPositive="Aceptar" 
            title="Cerrar Cuenta" show={showDelete} body={"¿Está seguro que desea cerrar la Cuenta?"} 
            onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} /> : null}
    </Layout >
    
  );
};