import { APIService } from "services";

export const getComissionData = () => {
    return APIService.api().get(`/comissions/?pageNo=1&pageSize=100`);
}

export const deleteReferido = (data) => {
    return APIService.api().post(`/comission_requests`, data);
}

export const getComissionManage = () => {
    return APIService.api().get(`/comission_requests/?pageNo=1&pageSize=100`);
}

export const acceptComission = (data, id) => {
    return APIService.api().patch(`/comission_requests/${id}`, data);
}
