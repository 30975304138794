import React, { Fragment, useState, useEffect } from "react";
import { Button, NavLink, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/reducers";
import { sidebarToggle, activateMenu } from "redux/actions";
import { useAppDispatch } from "redux/store";
import { ethers } from "ethers";
import { MenuItems } from "./MenuItems";
import left from '../assets/images/rent/FlechaDespliegueLeftBlack_icon.svg';
import right from '../assets/images/rent/FlechaDespliegueRightBlack_icon.svg';
import vid from './../assets/images/rent/LogoRent.png';
import logo from './../assets/images/rent/RentSimboloColor_icon.svg';
import { copyFile } from "fs";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleActiveMenu = (menuName: string) => {
        dispatch(activateMenu(menuName))
    }
    const isSidebar = useSelector((state: RootState) => state.template.sidebar);
    // const isExpanded = useSelector((state: RootState) => state.template.sidebar);
    const isActiveMenu = useSelector((state: RootState) => state.template.activeMenu);

    const handleMenuClick = (path) => {
        dispatch(activateMenu(path))
        navigate(path, { replace: true });
    }

    const handleSubMenuClick = (path) => {
        navigate(path, { replace: true });
    }

    useEffect(() => {
        document.body.classList.toggle('sidebar-toggled', isSidebar);
        console.log('sidebar', isSidebar);
    }, [isSidebar])




    const Icon = '<';
    const [isExpanded, setisExpanded] = useState(Icon);

    const style = {
        backgroundColor: 'transparent',
        opacity: '0.7'
    }

    const [toggleBar, settoggleBar] = useState({ marginLeft: '17rem' });
    const sidebarText = () => {
        if (isSidebar) {
            const img = '<';
            setisExpanded(img);
            settoggleBar({ marginLeft: '17rem' })

        } else {
            const img = '>';
            settoggleBar({ marginLeft: '6.5rem' })
            setisExpanded(img);
        }
    }

    // usetstate for storing and retrieving wallet details
    const [address, setAddress] = useState("");
    const [balance, setBalance] = useState("");

    const [ButtonsData, setButtonsData] = useState("");
    const [validateIcon, setValidateIcon] = useState("");
    const [validateLabel, setValidateLabel] = useState("");


    // Button handler button for handling a
    // request event for metamask
    useEffect(() => {
        if (window.ethereum) {
            // res[0] for fetching a first wallet
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((res) => accountChangeHandler(res[0]));
        } else {
            setButtonsData('install metamask extension!!');
        }

        if (userProfile.validation_status == -1) {
            setValidateIcon('fas fa-times fa-fw fs-14 m-l-5 text-danger');
            setValidateLabel('Error al validar la cuenta, revisa tu correo!');
        } else if (userProfile.validation_status == 0) {
            setValidateIcon('fas fa-history fa-fw fs-14 m-l-5 text-white');
            setValidateLabel('Cuenta sin validar');
        } else if (userProfile.validation_status == 1) {
            setValidateIcon('fas fa-circle-check fa-fw fs-14 m-l-5 text-success');
            setValidateLabel('Cuenta Validada!');
        } else if (userProfile.validation_status == 2) {
            setValidateIcon('fas fa-star fa-fw fs-14 m-l-5 text-warning');
            setValidateLabel('Cuenta Validada con AML Verificado!');
        } else {
            setValidateIcon('fas fa-history fa-fw fs-14 m-l-5 text-white');
            setValidateLabel('Cuenta sin validar');
        }

        if (isMobile) {
            // dispatch(sidebarToggle());
            // sidebarText();
            document.getElementById("sidebarToggle").click();
            // document.body.classList.toggle('sidebar-toggled', true);
        } else {
            //alert("estoy en desktop")
        }


    }, [])
    const btnhandler = () => {

        // Asking if metamask is already present or not
        if (window.ethereum) {

            // res[0] for fetching a first wallet
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((res) => accountChangeHandler(res[0]));
        } else {
            setButtonsData('install metamask extension!!');
        }
    };
    const btnhandlerDisconnect = () => {

        // Asking if metamask is already present or not
        if (window.ethereum) {
            // res[0] for fetching a first wallet
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((res) => accountChangeHandler(""));
            setBalance("");
        } else {
            setButtonsData('install metamask extension!!');
        }

    }
    // getbalance function for getting a balance in
    // a right format with help of ethers
    const getbalance = (address) => {

        // Requesting balance method
        window.ethereum
            .request({
                method: "eth_getBalance",
                params: [address, "latest"]
            })
            .then((balance) => {
                // Setting balance
                setBalance(ethers.utils.formatEther(balance));
            });
    };

    // Function for getting handling all events
    const accountChangeHandler = (account) => {
        // Setting an address data
        setAddress(account);
        // Setting a balance
        getbalance(account);
    };

    const [strAddress, setStrAddress] = useState("");
    const [strBalance, setStrBalance] = useState("");

    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : undefined;
    const profile = String(Object.values(userProfile)[12]);

    // console.log(userProfile);
    useEffect(() => {
        let pp = address.substring(0, 3);
        let lp = address.substr(-4);
        setStrAddress(pp + "..." + lp);
    }, [address])

    const [state, setState] = useState({ value: 'some\ntext', copied: false });

    const onCopy = () => {
        setState({ value: state.value, copied: true });
    };


    return (
        <>
            <ul className={isSidebar ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled deznav sidenav' : 'sidenav navbar-nav bg-gradient-primary sidebar sidebar-dark accordion deznav'} id="accordionSidebar">
                <a className="sidebar-brand d-flex mt-30 mb-30 align-items-center justify-content-center" href="#" onClick={() => handleMenuClick("/dashboard")}>
                    <div className={isSidebar ? 'sidebar-brand-icon' : 'sidebar-brand-icon dn'}>
                        <img src={logo} alt="" className="ImagoMainLogo" style={style} />
                    </div>
                    <div className={isSidebar ? 'sidebar-brand-text mx-3 dn' : 'sidebar-brand-text mx-3'}>
                        <Fragment>
                            <img src={vid} alt="" className="mainLogo" style={style} />
                        </Fragment>
                    </div>
                </a>

                <div className="main-profile mb-30">
                    <div className="image-bx">
                        <img src={profile} alt="" />
                        {/* <Link to={"#"}><i className="fa fa-cog" aria-hidden="true"></i></Link> */}
                    </div>
                    <h5 className={isSidebar ? 'mb-0 fs-16 dn' : 'mb-0 fs-16'} >
                        <span className="font-w400">Hola,</span> {Object.values(userProfile)[1]}
                        <i className={validateIcon} title={validateLabel}></i>

                    </h5>
                    <p className={isSidebar ? 'mb-0 fs-11 opacity-6 font-w400' : 'mb-0 fs-11 opacity-6 font-w400'} >
                        {Object.values(userProfile)[14]}
                        <CopyToClipboard onCopy={onCopy} text={Object.values(userProfile)[14]}>
                            <i className="m-l-10 pointer fa-regular fa-copy"></i>
                        </CopyToClipboard>
                    </p>
                    <p className={isSidebar ? 'mb-0 fs-11 opacity-6 font-w400' : 'mb-0 fs-11 opacity-6 font-w400'} >
                        {strAddress}
                        <CopyToClipboard onCopy={onCopy} text={address}>
                            <i className="m-l-10 pointer fa-regular fa-copy"></i>
                        </CopyToClipboard>
                    </p>
                </div>

                {MenuItems.map((item, i) => {
                    return item.subMenu && item.subMenu.length > 0 ?
                        <li className="nav-item" key={`Menu-${i}`}>
                            <NavLink className={isActiveMenu === item.title ? 'nav-link pt-0 btn btn-link' : 'collapsed nav-link pt-0 btn btn-link'} data-toggle="collapse"
                                aria-expanded={isActiveMenu === item.title ? true : false} onClick={() => handleActiveMenu(item.title)}>
                                <span className="personalizedicon "><div className={item.icon}></div></span>
                                <span className={isSidebar ? ' dn' : ' '}>{item.title}</span></NavLink>
                            <div id="collapseTwo" className={isActiveMenu === item.title ? "collapse show" : "collapse"} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="py-2 text-white collapse-inner rounded">
                                    <ul className=" pl-2">
                                        {
                                            item.subMenu.map((sub, k) => {
                                                return <li className="nav-item" key={`Menu-${k}`}><Button key={`SubMenu-${k}`} variant="link" className=" text-white fs-13 collapse-item pt-0 nav-link" onClick={() => handleSubMenuClick(sub.path)}>
                                                    <i className={sub.icon}></i><span>{sub.title}</span></Button></li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </li>

                        :
                        <li className={isActiveMenu === item.path ? "nav-item active" : "nav-item"} key={`Menu-${i}`} >
                            <Button variant="link" className="nav-link pt-0" onClick={() => handleMenuClick(item.path)}>
                                <span className={isSidebar ? 'personalizedicon ml-25' : 'personalizedicon'}><div className={item.icon}></div></span>
                                <span className={isSidebar ? ' dn' : item.textClass}>
                                    {item.title}
                                </span>
                                <span className={item.altIcon == 'soon' ? 'personalizedicon2' : 'dn'}><div className='incon-soon'></div></span>
                            </Button>

                        </li>
                }

                )}

                <Container className={`justify-content-center w-100 container-fluid text-center`} >
                    <a href="" className="footerLinks" onClick={(event) => { event.preventDefault(); window.open("https://nurent.net/terminos-y-condiciones/", "_blank") }}>Términos de uso</a>
                    <a href="" className="footerLinks" onClick={(event) => { event.preventDefault(); window.open("https://nurent.net/tratamiento-datos/", "_blank") }}>Política general de tratamiento de datos</a>
                    <a href="" className="footerLinks" onClick={(event) => { event.preventDefault(); window.open("https://nurent.net/no-finance-registry/", "_blank") }}>No registramos instrumentos financieros</a>
                    <span className="footerLinks" >Copyright &copy; Rent 2022</span>
                </Container>

            </ul>
            <div className="text-center d-none d-md-inline" style={toggleBar}>
                <button className="sidebarToggle" id="sidebarToggle" onClick={() => [dispatch(sidebarToggle()), sidebarText()]}>{isExpanded}</button>
            </div>
        </>
    );
};

export default Sidebar;


