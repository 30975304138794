import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useAppDispatch } from "redux/store";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { validateCode, forwardCode } from "services/usersService";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import { useFormik } from 'formik';
import { ethers } from "ethers";
import logo from '../../assets/images/rent/RentLogoColor02_icon.svg';
import * as yup from 'yup';

export const WalletsModal: React.FC<{letmodal: boolean}> = ({letmodal}) => {

    const navigate = useNavigate();    
    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        // alert(letmodal);
        setIsOpen(letmodal);
    }, [letmodal])
    

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          height: '340px',
          transform: 'translate(-50%, -50%)',
        },
    };
    const style = {
        logo: {
            width:'50%',
        }
      }
   
    const [validateResponse, setvalidateResponse] = useState('');
    Modal.setAppElement('#root');

    const submitValidation = () => {
        alert("se redireccionará al sitio de KYC");
        setIsOpen(false);
    }

    
    // usetstate for storing and retrieving wallet details
    const [address, setAddress] = useState("");
    const [balance, setBalance] = useState("");
    const [ButtonsData, setButtonsData] = useState("");

    // Button handler button for handling a
    // request event for metamask
    useEffect(() => {
        if (window.ethereum) {
        // res[0] for fetching a first wallet
        window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((res) => accountChangeHandler(res[0]));
        } else {
            setButtonsData('install metamask extension!!');
        }
    }, [])
    const btnhandler = () => {
    
        // Asking if metamask is already present or not
        if (window.ethereum) {
    
        // res[0] for fetching a first wallet
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((res) => accountChangeHandler(res[0]));
        } else {
            setButtonsData('install metamask extension!!');
        }
        window.location.reload();
    };
    const btnhandlerDisconnect = () => {

        // Asking if metamask is already present or not
        if (window.ethereum) {
            // res[0] for fetching a first wallet
            window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((res) => accountChangeHandler(""));
            setBalance("");
        } else {
            setButtonsData('install metamask extension!!');
        }     

    }
    // getbalance function for getting a balance in
    // a right format with help of ethers
    const getbalance = (address) => {
    
        // Requesting balance method
        window.ethereum
        .request({ 
            method: "eth_getBalance", 
            params: [address, "latest"] 
        })
        .then((balance) => {
            // Setting balance
            setBalance(ethers.utils.formatEther(balance));
        });
    };
    
    // Function for getting handling all events
    const accountChangeHandler = (account) => {
        // Setting an address data
        setAddress(account);
        // Setting a balance
        getbalance(account);
    };



    return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="modal_Box">
                <div className="row modal_header m-l-5 m-r-5">
                    <div className="col-sm-11 p-l-0">
                        <h2>Conecta tu Wallet</h2>
                    </div>
                    <div className="col-sm-1 p-r-0">
                        <div className="pull-right p-5px default-select bg-white b-none">
                            <div className="personalizedicon mr-0 pt-0" onClick={closeModal}>
                                <div className="icon_Close_icon w-15"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row modal_body">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Button onClick={btnhandler}  title="Conectar Wallet" className={ balance !== "" ? "btn-rent-invert dn" : "btn-rent-success " } variant="primary">
                                    Conectar con Metamask
                                </Button>
                                <Button onClick={btnhandlerDisconnect} title="Desconectar Wallet" className={ balance !== "" ? "btn-danger" : "btn-rent-invert dn" } variant="primary">
                                    Desconectar Metamask
                                </Button>
                            </div>
                        </div>
                        <div className="row m-t-50">
                            <div className="col-md-12 text-center">
                                <img src={logo} alt="" style={style.logo}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
    );
};