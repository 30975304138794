import React, { useEffect, useState } from "react";
import Layout from "template";
import { cancelOfert, getAllOffertsSent, getTableOfferts_Detail } from "services/offerts_module";
import { Button, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router";


export const Offers_sent: React.FC = () => {
    const [rDataTable, setRDataTable] = useState([]);
    const [rData, setRData] = useState([]);
    let uri = "/secondary_market_detail";
    let parts = uri.split("/");
    const navigate = useNavigate();


  const offertsModule = ([]) => {
    const newpath = `/offers_module`;
    navigate(newpath, { replace: true });
  };

      useEffect(() => {
        getAllOffertsSent().then((response) => {
          const dataRecords = response.data.records;
          setRData(dataRecords);
          console.log("SOY OFERTAS ENVIADAS",response)
        });
      }, []);
    
      useEffect(() => { 
        getTableOfferts_Detail().then((response) => {
          setRDataTable(response.data.records)
        });
      }, []); 
    
      if (!rData) {
        return null; 
      }

    const cancelar = (data) => {
        const dataSend = { sale_id: data.sale_id, nft_id: data.nft_id, buyer: data.buyer, price: data.price, status: data.status }
        cancelOfert(dataSend, data.id).then((_) => {
          window.location.reload();
        });
    }

    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;

      return (
        <Layout
          title="Ofertas Enviadas"
          icon="icon_MisNftColor_icon w-30"
          backlink={"#"}
        >
          
          <div className="row mb-2 container-fluid pt-body">
          <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
          <h2 className="font-w600 mr-auto " style={{ width: "450px"}}>
            <span className="mm-active">
              <div className="personalizedicon">
                  <h4 className="text-white text-left">Ofertas Enviadas</h4>
              </div>
            </span>
           
          </h2>
          <Button
            className="btn m-r-30 btn-info btn-rent-invert-prueba"
            style={{ width: "182px", height: "45px" }}
          >
            <span className="item" onClick={() => offertsModule([])}>Ofertas recibidas</span>
            <div>
              <span className="d-block fs-15">{}</span>
            </div>
          </Button>
          <Button
            className="btn m-r- btn-info btn-rent-invert-prueba"
            style={{ width: "182px", height: "45px" }}
          >
            <span className="item">Ofertas enviadas</span>
            <div>
              <span className="d-block fs-15">{}</span>
            </div>
          </Button>
          <div>
            
          </div>
        </div>
          <div className="col-lg-12 col-sm-12">
          {rData.map((r, i) => (
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-md-6">
                  <div className="card">
                      <div className="card-body p-20">
                          <div className="row">
                              <div className="col-xl-12 p-0">
                                  <div className="contenedor">
                                      <figure>
                                          <img
                                              className="img-fluid"
                                              src={r.file}
                                              alt="no image"
                                          />
                                      </figure>
                                  </div>
                                  <p className="text-rent">
                                      <NavLink className='nav-link pl-0 pt-0 btn btn-link text-left' data-toggle="collapse">
                                          <div className="personalizedicon m-r-5"> <div className="icon_UbicationColor_icon w-15"></div> </div><span className="item text-white">LLC:{" "}{r.description}</span>
                                      </NavLink>
                                  </p>
                                  <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_PropiedadesColor_icon w-15"></div> </div>Precio sugerido: <span className="item text-white">{r.price} RENT</span></p>
                                  <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_UbicationColor_icon w-15"></div> </div>Precio promedio:{" "}<span className="item text-white"> {r.price} RENT</span></p>
                                  <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_PropiedadesColor_icon w-15"></div> </div>Total participaciones: <span className="item text-white">{r.totalParticipaciones}</span></p>
                            
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-md-6 col-sm-12">


                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="product-detail-content">
                            <div style={{ overflow: "auto", maxHeight: "535px" }}>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Buyer</th>
                                    <th scope="col" style={{width:'150px'}}>Price</th>
                                    <th scope="col" style={{width:'250px'}}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rDataTable?.map((data) => (
                                    (data.sale_id == r.id) ? 
                                    <tr>
                                      <td className="text-white">{data.name+' '+data.last_name}</td>
                                      <td className="text-white">{data.price}</td>
                                      <td className="text-white">
                                      {(data.buyer == userProfile.id) ? 
                                      <Button
                                          variant="link"
                                          onClick={() => cancelar(data)}
                                          className="btn text-white btn-primary m-0 btn-sm btn-rent-invert" 
                                        > 
                                          Cancelar Oferta
                                      </Button>
                                      : null}
                                      </td>
                                    </tr>
                                    : null
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          ))}
    </div>
              
          </div>
        </Layout>
      );
    };
    
