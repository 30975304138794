import { APIService } from "services";

export const getSecondaryMarkets_Detail = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllSecondaryMarkets_Detail(pageNo,pageSize);
    }
    else{
        try {
            res = await searchSecondaryMarkets_Detail(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }   
}

export const getAllSecondaryMarkets_Detail = (pageNo,pageSize) => {
    return APIService.api().get(`/secondary_market_detail/?pageNo=${pageNo}&pageSize=${pageSize}`)
}

export const searchSecondaryMarkets_Detail = (searchKey,pageNo,pageSize) => {
    return APIService.api().get(`/secondary_market_detail/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}

 export const getOneSecondaryMarkets_Detail = (id) => {
    return APIService.api().get(`/nft_market/${id}`)
}

export const getTableSecondaryMarkets_Detail = (id) => {
    return APIService.api().get(`nft_offers/search/${id}/?pageNo=1&pageSize=100`)  ///search/:searchKey
}

export const deleteOneSecondaryMarkets_Detail = (id) => {
    return APIService.api().delete(`/nft_offers/${id}`)
}

export const addOneSecondaryMarkets_Detail = (data) => {

    return APIService.api().post(`/nft_offers/`, {'sale_id': data.sale_id,'nft_id': data.nft_id,'price': data.price})

}
