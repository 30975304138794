import React, { useState } from "react";
import Parser from 'html-react-parser';

export const Tabla: React.FC<{lista:string}> = ({lista}) => {
    console.log("->",lista);
    const listaElementos = lista ? JSON.parse(lista): [{'total':'0','name':'No tienes Contratos =(','percent':'0'}];

    return (
          <table className="table table-striped">
            <thead>
                <tr>
                    <th>Franquicia</th>
                    <th>Valor Franquicia</th>
                    <th>Rentabilidad esperada</th>
                </tr>
            </thead> 
            <tbody>
                {
                listaElementos?.map((event) => (
                    <tr>
                        <td className="text-white">{event.name}</td>
                        <td className="text-white">{event.total}</td>
                        <td className="text-white">{Parser(event.percent)}</td>
                    </tr>
                )
                )
            }
            </tbody>
          </table>
    );
  
}