import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useAppDispatch } from "redux/store";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { validateCode, forwardCode } from "services/usersService";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import { useFormik } from 'formik';
import { ethers } from "ethers";
import logo from '../../assets/images/rent/RentLogoColor02_icon.svg';
import * as yup from 'yup';
import QRCode from "react-qr-code";

export const QrModal: React.FC<{letmodal: boolean}> = ({letmodal}) => {

    const navigate = useNavigate();    
    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        // alert(letmodal);
        setIsOpen(letmodal);
    }, [letmodal])
    

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          height: '490px',
          transform: 'translate(-50%, -50%)',
        },
    };
    const style = {
        logo: {
            width:'50%',
        }
      }
   
    const [validateResponse, setvalidateResponse] = useState('');
    Modal.setAppElement('#root');

    const submitValidation = () => {
        alert("se redireccionará al sitio de KYC");
        setIsOpen(false);
    }


    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
    const value = 'https://go.moyoai.com/4Rvr22fq/'+userProfile.email;


    return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="modal_Box">
                <div className="row modal_header m-l-5 m-r-5">
                    <div className="col-sm-11 p-l-0">
                        <h2>Valida tu cuenta</h2>
                    </div>
                    <div className="col-sm-1 p-r-0">
                        <div className="pull-right p-5px default-select bg-white b-none">
                            <div className="personalizedicon mr-0 pt-0" onClick={closeModal}>
                                <div className="icon_Close_icon w-15"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row modal_body">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <p>
                                    Escanea el siguiente codigo QR desde tu teléfono celular para realizar el proceso de validación de tu cuenta
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
                                    <QRCode

                                    style={{ height: "auto" }}
                                    value={value}
                                    viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row m-t-20">
                            <div className="col-md-12 text-center">
                                <p>
                                    También puedes realizar la validación haciendo clic 
                                    <a target='_blank' href={value}> Aquí </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
    );
};