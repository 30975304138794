import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setUsersList, setUsersMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getReferrals } from "services/usersService";
import Layout from "template";
import { Constant } from "template/Constant";
import { UsersTable } from "./table";

export const Referrals: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.users);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');

  const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
  const referral = String(Object.values(userProfile)[14]);

  console.log(referral);

  const getData = (page, pageSize, searchKey) => {
    getReferrals(referral).then((response) => {
      if (response && response.records) {
        dispatch(setUsersList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.totalCount, searchKey: searchKey }));
      } else {
        dispatch(setUsersMessage("No Record Found"));
      }
    })
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setUsersMessage(''));
    setAction('edit');
  }
  return (
    <Layout title="Titulo"  icon="icon_DashboardColor_icon w-30" backlink={"#"} >
      <div className="container-fluid pt-body">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0">Users</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setUsersMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
            <UsersTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />
        </div>

      </div>
    </Layout >
  );
};

