import { APIService } from "services";

export const getSecondaryMarkets = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllSecondaryMarkets();
    console.log("mostrar todosss", res);
  } else {
    try {
      console.log("buscando mercados");
      res = await searchSecondaryMarkets(search, pageNo, pageSize);
      console.log("-", res);
    } catch (err) {
      console.log("error");
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

 /*export const getAllSecondaryMarkets = (pageNo, pageSize) => {
 return APIService.api().get(`secondary_markets/?pageNo=${pageNo}&pageSize=${pageSize}`);
};*/

export const getAllSecondaryMarkets = () => {
  return APIService.api().get(`nft_market/?pageNo=1&pageSize=100`);
};

export const searchSecondaryMarkets = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(`/secondary_markets/search/?searchKey=${searchKey}&pageNo=${pageNo}&pageSize=${pageSize}`);
};
