import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IFranchises_Detail {
id:number,
franchise_id:string,
profit_rent?:string,
sell_rent?:string,
token_price?:string,
int_token?:string,
total_tokens?:string,
esi_validation?:string,
documentation?:string,
doc_emision?:string,
plans?:string,
created_at?:Date,
title?:string,
type_info?:string,
llc_total_activos?:string,
llc_valor_activos?:string,
llc_onboarding?:string,
llc_ingreso_ano1?:string,
llc_revenue?:string,
llc_gastos?:string,
llc_reserva?:string,
llc_management?:string,
llc_utilidad?:string,
llc_roi?:string,
llc_utilidad_neta?:string,
llc_roi_neto?:string,
llc_revalorizacion_estimada?:string,
tk_valor_ticket?:string,
tk_total_socios?:string,
tk_total_particicaciones?:string,
tk_participacion_ticket?:string,
tk_ingresos_ticket?:string,
tk_revenue_esperado?:string,
tk_utilidad_neta?:string,
tk_roi_neto?:string,
tk_utilidad_neta_optimo?:string,
tk_roi_neto_optimo?:string,
tk_revalorizacion_estimada?:string,
}

interface IFranchises_DetailData {
    list?: Array<IFranchises_Detail>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IFranchises_DetailData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const franchises_detailSlice = createSlice({
    name: "franchises_detail",
    initialState,
    reducers: {
        setFranchises_DetailList: (state, _action: PayloadAction<IFranchises_DetailData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetFranchises_DetailToInit: (state) => {
            state = initialState;
        },
        setFranchises_DetailMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setFranchises_DetailList, resetFranchises_DetailToInit, setFranchises_DetailMessage } = franchises_detailSlice.actions;

export default franchises_detailSlice.reducer;

