import React, { useState, useEffect, useContext, createContext } from "react";
import { Alert, Button, Card, Col, Form, InputGroup,Tab, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { setFranchises_DetailList, setFranchises_DetailMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getFranchises_Detail } from "services/franchises_detailService";
import Layout from "template";
import { Constant } from "template/Constant";
import { getOneFranchises, getCurrency } from "services/franchisesService";
import { Franchises_DetailForm } from "./form";
import { Franchises_DetailTable } from "./table";
import Parser from 'html-react-parser';
import franchises_detail from "redux/slices/franchises_detail";
import { useNavigate } from "react-router";
import { CartDispatchContext, addToCart } from "../../contexts/cart";
import { contractAbi, contractAdress, providerTmp } from "../../contexts/contractAbiSandBox";
import { ethers } from "ethers";
import { addNfts, updateNfts } from "services/nftsService";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { WompiModal } from "../modal/wompiModal";
import { Franchises_DetailWithWallet } from "./indexWithWallet";
import { Franchises_DetailWithNoWallet } from "./indexWithNoWallet";


const CurrentChart = loadable(() =>
	pMinDelay(import("../zenix/Chartjs/line1"), 1000)
);


export const Franchises_Detail: React.FC = (props) => {
    
   // usetstate for storing and retrieving wallet details
   const [address, setAddress] = useState("");
   const [action, setAction] = useState(false);
    
    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
    const profile = String(Object.values(userProfile)[12]);

    // Function for getting handling all events
   const accountChangeHandler = (account) => {
    // Setting an address data
    setAddress(account);
  };

    useEffect(() => {
        if (window.ethereum) {
        // res[0] for fetching a first wallet
        window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((res) => setAction(true));
        } else {
            setAction(false)
        }    
        
      }, [])

  return (
        <>
            <Franchises_DetailWithNoWallet />
            {/* {action ?   <Franchises_DetailWithWallet /> : <Franchises_DetailWithNoWallet />}; */}
        </>
         
  );
};

