import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { userRegister } from "services/authService";
import { userUpdate } from "services/usersService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import logo from '../assets/images/rent/RentLogoBlack_icon.svg';
import {WindowModal} from '../users/modal';

const UpdateProfileForm: React.FC = () => {


  const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;

//   console.log(userProfile);
    const navigate = useNavigate();
    const handleClose = () => {
        navigate('/', { replace: true });
    }
    const location = useLocation();
    let uri = location.pathname;
    let parts = uri.split("/");
    let query = parts[2];

    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    const formik = useFormik({
        initialValues: {
            username: userProfile.email,
            name: userProfile.name,
            lastname: userProfile.last_name,
            sexo: userProfile.sexo,
            birthday: userProfile.birthday,
            phone: userProfile.phone
        },

        onSubmit: async (values) => {

            const object = {username: values.username,
                            name: values.name,
                            lastname: values.lastname,
                            sexo: values.sexo,
                            birthday: values.birthday,
                            phone: values.phone}

            const response = await userUpdate(object);
            if (response) {
                alert("Usuario Actualizado")
                //dispatch(setJWTToken(response));
                //setopenModal(true)
                //navigate('/franchises', { replace: true });

            } else {
                dispatch(setError("Invalid Credentials"))
            }
        },
        validationSchema: yup.object({
            username: yup.string().trim().required('Username is required'),
            name:     yup.string().trim().required('name'),
            lastname: yup.string().trim().required('lastname'),
            sexo: yup.string().trim().required('sexo'),
            birthday: yup.string().trim().required('birthday'),
            phone: yup.string().trim().required('phone')
        }),
    });


    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const [openModal, setopenModal] = useState(false);
    const styleSelect = {
        fontSize: '13px',
        paddingTop: '0rem !important', 
        paddingBottom: '0rem !important',
        paddingLeft: '10px !important',
        width:'85%',
    }
    
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

    // const openModal = () => {
    //     setIsOpen(true);
    // }
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
        iconForm:{
            marginRight: '10px',
        },
        formRegister:{
            width:'85%',
        },
        colorSVG:{
            color:'#FFFFFF',
        }
    };
    


    Modal.setAppElement('#root');

    useEffect(() => {
        document.body.classList.toggle('LoginBp', true);
    }, [])


    return (
        <Form className="user m-t-20"  onSubmit={formik.handleSubmit}>
            <div className="row m-b-20">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <Form.Group>
                        <div className="personalizedicon hidden-xs"  style={customStyles.iconForm}>
                            <div className="icon_Usuario_icon_white w-30"></div>
                        </div>
                        <Form.Control type="text" className="form-control form-control-user" id="name" 
                            value={formik.values.name}
                            placeholder="Nombre"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.name && !!formik.errors.name}
                            isValid={!!formik.touched.name && !formik.errors.name}
                            style={customStyles.formRegister}
                        ></Form.Control>
                        {formik.errors.name && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <Form.Group>
                        <div className="personalizedicon hidden-xs"  style={customStyles.iconForm}>
                            <div className="icon_Usuario_icon_white w-30" style={customStyles.colorSVG}></div>
                        </div>
                        <Form.Control type="text" className="form-control form-control-user" id="lastname"
                            value={formik.values.lastname}
                            placeholder="lastname"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.lastname && !!formik.errors.lastname}
                            isValid={!!formik.touched.lastname && !formik.errors.lastname}
                            style={customStyles.formRegister}
                        ></Form.Control>
                        {formik.errors.lastname && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.lastname}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>
            </div>
            <div className="row m-b-20">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <Form.Group>
                        <div className="personalizedicon hidden-xs"  style={customStyles.iconForm}>
                            <div className="icon_Correo_icon_white w-30"></div>
                        </div>
                        <Form.Control type="email" className="form-control form-control-user" id="username" 
                            value={formik.values.username}
                            placeholder="E-mail"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.username && !!formik.errors.username}
                            isValid={!!formik.touched.username && !formik.errors.username}
                            style={customStyles.formRegister}
                        ></Form.Control>
                        {formik.errors.username && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.username}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <Form.Group>
                        <div className="personalizedicon text-white hidden-xs" style={customStyles.iconForm}>
                            <div className="icon_Cel_icon_white w-30"></div>
                        </div>
                        <Form.Control type="text" className="form-control form-control-user" id="phone" 
                            value={formik.values.phone}
                            placeholder="Teléfono"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.phone && !!formik.errors.phone}
                            isValid={!!formik.touched.phone && !formik.errors.phone}
                            style={customStyles.formRegister}
                        ></Form.Control>
                        {formik.errors.phone && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.phone}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>
            </div>
            <div className="row m-b-20">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <Form.Group>
                        <div className="personalizedicon hidden-xs"  style={customStyles.iconForm}>
                            <div className="icon_Sexo_icon_white w-30"></div>
                        </div>
                        <select aria-label="Default select example" className="form-control" id="sexo"
                            value={formik.values.sexo}
                            placeholder="sexo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style = {styleSelect}
                        >
                            <option>Selecciona tu Sexo</option>
                            <option value="Hombre">Hombre</option>
                            <option value="Mujer">Mujer</option>
                            <option value="Otro">Otro</option>
                        </select>
                        {formik.errors.sexo && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.sexo}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>
                <div className="col-sm-6 mb-3 mb-sm-0">
                <Form.Group>
                        <div className="personalizedicon hidden-xs"  style={customStyles.iconForm}>
                            <div className="icon_FechaNacimiento_icon_white w-30"></div>
                        </div>
                        <Form.Control type="date" className="form-control form-control-user" id="birthday" 
                            value={formik.values.birthday}
                            placeholder="Fecha de Nacimiento"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.birthday && !!formik.errors.birthday}
                            isValid={!!formik.touched.birthday && !formik.errors.birthday}
                            style={customStyles.formRegister}
                        ></Form.Control>
                        {formik.errors.birthday && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.birthday}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>  
                </div>
            </div>

            <div className="row justify-content-center m-t-30">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <Button type="submit" className="btn btn-primary btn-user btn-block btn-rent-white">Actualizar Perfil</Button>
                </div>
            </div>
            {openModal ? <WindowModal email={formik.values.username} letmodal={true} />: null}
        </Form>
                                        

    );
};

export default UpdateProfileForm;

