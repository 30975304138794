import { useSelector } from "react-redux";
import Layout from "template";
import { RootState } from "redux/reducers";
import React, { useEffect, useState } from "react";
import { Button, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router";
import { getAllSecondaryMarkets } from "services/secondary_marketsService";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  iconForm: {
    marginRight: "10px",
  },
  formRegister: {
    width: "85%",
  },
  colorSVG: {
    color: "#FFFFFF",
  },
};

export const Secondary_markets: React.FC = () => {
  const [rData, setRData] = useState([]);
  const navigate = useNavigate();
  const DetailMarkets = (id: string) => {
    const newpath = `/secondary_market_detail/${id}`;
    navigate(newpath, { replace: true });
  };

  useEffect(() => {
    getAllSecondaryMarkets().then((response) => {
      const dataRecords = response.data.records;
      setRData(dataRecords);
    });
  }, []);


  return (
    <Layout
      title="Mercados secundarios"
      icon="icon_MisNftColor_icon w-30"
      backlink={"#"}
    >
      <div className="container-fluid pt-body">
        <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
          <h2 className="font-w600 mr-auto ">
            <span className="mm-active">
              <div className="personalizedicon">
                {" "}
                <div className="icon_MisNftColor_icon w-30"></div>{" "}
              </div>
            </span>
            {"Mercados secundarios"}
          </h2>
          {/* <Button
            className="btn m-r-30 btn-info btn-rent-invert-prueba"
            style={{ width: "182px", height: "45px" }}
          >
            <span className="item">Filtro: Filtrado</span>
            <div>
              <span className="d-block fs-15 text-white">{}</span>
            </div>
          </Button>
          <Button
            className="btn m-r-30 btn-info btn-rent-invert-prueba"
            style={{ width: "182px", height: "45px" }}
          >
            <span className="item">Filtro: Filtrado</span>
            <div>
              <span className="d-block fs-15 text-white">{}</span>
            </div>
          </Button> */}
          <div>
            <div className="input-group rounded">
              <input
                type="search"
                className="form-control form-control-user"
                placeholder="Buscar"
                aria-label="Search"
                aria-describedby="search-addon"
                style={{ width: "262px", height: "45px" }}
              />
            </div>
          </div>
        </div>

        <div row- mb-2></div>
        <div className="row">
          {rData.map((r, i) => (
            <div key={i} className="col-md-6">
              <div
                className="card mb-3"
                style={{ height: "265px", padding: "10px" }}
              >
                <div className="row g-0" style={{ padding: "10px" }}>
                  <div className="col-md-4" style={{ padding: "10px" }}>
                    <figure>
                      <img className="img-fluid" src={r.file} alt="no image" />
                    </figure>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-rent">
                      Participación: <span> { r.description} </span>{" "}
                      </h5>
                      <p className="text-rent">
                        <div className="personalizedicon m-r-5">
                          {" "}
                          <div className="icon_PropiedadesColor_icon w-15"></div>{" "}
                        </div>
                        Compra desde: {r.current_owner}
                        <span className="item"> </span>
                      </p>
                      <p className="text-rent">
                        <div className="personalizedicon m-r-5">
                          {""}
                          <div className="icon_PropiedadesColor_icon w-15"></div>{" "}
                        </div>
                        Renta anuel estimada: {r.current_value}
                        <span className="item"> </span>
                      </p>
                      <p className="text-rent">
                        <div className="personalizedicon m-r-5">
                          {" "}
                          <div className="icon_PropiedadesColor_icon w-15"></div>{" "}
                        </div>
                        Renta anual actual: {r.sale_price}
                        <span className="item"> </span>
                      </p>
                      <Button
                        variant="link"
                        className="justify-content-end btn m-r-10 btn-primary btn-rent-invert"
                        onClick={() => DetailMarkets(r.id)}
                        style={{
                          float: "right",
                          width: "150px",
                          height: "45px",
                        }}
                      >
                        Detalles
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};
