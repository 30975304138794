import "styles/app.scss";
import "styles/global.scss";
import "assets/styles/styles.css";
import "assets/styles/main_menu.css";
import CartProvider from "contexts/cart";
import CheckoutProvider from "contexts/checkout";
// import sideBarContext from "contexts/sideBar";
import { Provider } from "react-redux";
import store from "redux/store";
import Pages from "pages";

function App(): JSX.Element {



  return (
    <Provider store={store}>

      <CartProvider>
        <CheckoutProvider>

          <div className="App">
            <Pages />
          </div>

        </CheckoutProvider>
      </CartProvider>
    </Provider >
  );
}

export default App;