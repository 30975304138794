import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setWallet_TransactionsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addWallet_Transactions, updateWallet_Transactions } from "services/wallet_transactionsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Wallet_TransactionsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={ammount:0,transaction_status:'',observation:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateWallet_Transactions(row.id,values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setWallet_TransactionsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setWallet_TransactionsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addWallet_Transactions(values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setWallet_TransactionsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setWallet_TransactionsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
            ammount: yup.number().required('ammount is required'),
            transaction_status: yup.string().required('transaction_status is required'),
            observation: yup.string().required('observation is required'),
        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h1  className="h3 mb-0 text-gray-800">Agregar Transacción de pruebas
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}  style={{marginLeft:'100px'}}>  
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">Volver al Listado</span>
                    </Button>
                </h1>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group>
                        <label className="form -control-label">Valor</label>
                        <Form.Control type="number" name="ammount" className="form-control" value={formik.values.ammount}
                        onChange={formik.handleChange}
                        onBlur ={formik.handleBlur}
                        isInvalid ={!!formik.touched.ammount && !!formik.errors.ammount}
                        isValid ={!!formik.touched.ammount && !formik.errors.ammount}
                        >

                    </Form.Control>
                        {
                            formik.errors.ammount && (
                            <Form.Control.Feedback type="invalid">
                                <>{formik.errors.ammount}</>
                            </Form.Control.Feedback>
                        )}
                        </Form.Group>
                        <Form.Group>
                        <label className="form -control-label">Estado</label>
                        <Form.Control type="text" name="transaction_status" className="form-control" value={formik.values.transaction_status}
                        onChange={formik.handleChange}
                        onBlur ={formik.handleBlur}
                        isInvalid ={!!formik.touched.transaction_status && !!formik.errors.transaction_status}
                        isValid ={!!formik.touched.transaction_status && !formik.errors.transaction_status}
                        >

                    </Form.Control>
                        {
                            formik.errors.transaction_status && (
                            <Form.Control.Feedback type="invalid">
                                <>{formik.errors.transaction_status}</>
                            </Form.Control.Feedback>
                        )}
                        </Form.Group>
                        <Form.Group>
                        <label className="form -control-label">Observación</label>
                        <Form.Control type="text" name="observation" className="form-control" value={formik.values.observation}
                        onChange={formik.handleChange}
                        onBlur ={formik.handleBlur}
                        isInvalid ={!!formik.touched.observation && !!formik.errors.observation}
                        isValid ={!!formik.touched.observation && !formik.errors.observation}
                        >

                    </Form.Control>
                        {
                            formik.errors.observation && (
                            <Form.Control.Feedback type="invalid">
                                <>{formik.errors.observation}</>
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

