import React, { useEffect, useState } from "react";
import { Button, Tab } from "react-bootstrap";
import Layout from "template";
import { useNavigate } from "react-router";
import { addOneSecondaryMarkets_Detail, deleteOneSecondaryMarkets_Detail, getOneSecondaryMarkets_Detail, getTableSecondaryMarkets_Detail } from "services/secondary_marketDetailService";
import { useLocation, useParams } from "react-router-dom";
import Modal from 'react-modal';
import Parser from 'html-react-parser';



export interface IFranchises_Detail {
  id:number,
  franchise_id:string,
  profit_rent?:string,
  sell_rent?:string,
  token_price?:string,
  int_token?:string,
  total_tokens?:string,
  esi_validation?:string,
  documentation?:string,
  doc_emision?:string,
  plans?:string,
  created_at?:Date,
  title?:string,
  type_info?:string,
  llc_total_activos?:string,
  llc_valor_activos?:string,
  llc_onboarding?:string,
  llc_ingreso_ano1?:string,
  llc_revenue?:string,
  llc_gastos?:string,
  llc_reserva?:string,
  llc_management?:string,
  llc_utilidad?:string,
  llc_roi?:string,
  llc_utilidad_neta?:string,
  llc_roi_neto?:string,
  llc_revalorizacion_estimada?:string,
  tk_valor_ticket?:string,
  tk_total_socios?:string,
  tk_total_particicaciones?:string,
  tk_participacion_ticket?:string,
  tk_ingresos_ticket?:string,
  tk_revenue_esperado?:string,
  tk_utilidad_neta?:string,
  tk_roi_neto?:string,
  tk_utilidad_neta_optimo?:string,
  tk_roi_neto_optimo?:string,
  tk_revalorizacion_estimada?:string,
  }
  
  interface IFranchises_DetailData {
      list?: Array<IFranchises_Detail>;
      pageNo: number;
      pageSize: number;
      searchKey?: string;
      totalCount?: number;
      message?: string;
  }

  const customStylesModalRetiro = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      height: '300px',
      transform: 'translate(-50%, -50%)',
    },
};

export const Secondary_market_detail: React.FC = () => {
  const [rDataTable, setRDataTable] = useState([]);
  const [esAceptada, setEsAceptada] = useState(false);
  const [dataModal, setDataModal] = useState({ sale_id: null,  nft_id: null, price: null  });
  const [mensajeModal, setMensajeModal] = useState(null);
  const [tituloModal, setTituloModal] = useState(null);
  const [modalRetiro, setModalRetiro] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [mostrarVender, setMostratVender] = useState(false); 
  const [rData, setRData] = useState({file: '', description: '' ,current_value: '', current_owner: '', new_owner: '', nft_id: '', sale_price: '', status: ''});
  const [franchise, setFranchise] = useState({ 'id': '', 'name': '', 'description': '', 'avatar': '', 'city': '', 'country': '', 'pricing_list': '', 'discounts': '', 'min_invest': '', 'expexted_profit': '', 'qualification': '', 'google_map': '', 'rents': '', 'nft_description': '', 'valor_ticket': '', 'franchise_type': '', 'seguimiento_link': '', 'unit_pricing_list':'', 'status': '', 'total_rents': 0, 'rents_disponibles': 0});
  const [marketData, setMarketData] = useState({id: '', nft_id: '', current_owner: '', current_value: '' ,sale_price: '', status: '', created_at: '', new_owner: '', participaciones: ''});
  const [valorInput, setValorInput] = useState("0");

  const viewLocations = (path) => {
    const newpath = '/locations/' + path;
    navigate(newpath, { replace: true });
  }

  const handleViewNft = (id: string) => {
  const newpath = `/collection/${id}`;
  navigate(newpath, { replace: true });
  };

  useEffect(() => { 
    getOneSecondaryMarkets_Detail(id).then((response) => {
      const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
      const profile = Number(Object.values(userProfile)[0]);
      const mostrarVenderCliente = profile === response.data.nft_detail.owner
      setMostratVender(mostrarVenderCliente);
      setRData(response.data.nft_detail);
      setFranchise(response.data.franshise)
      setMarketData(response.data.nft_market)
    });
  }, [id]); 

  useEffect(() => { 
    getTableSecondaryMarkets_Detail(id).then((response) => {
      
      // if(response.data.estado == '200'){
        console.log(response.data.records.length);
        setRDataTable((response.data.records.length > 0) ? response.data.records : []);
      // }
    });
  }, []); 

  if (!rData) {
    return null; 
  }

const vender = (data) => {
    setEsAceptada(true)
    setDataModal(data)
    setModalRetiro(true)
    setTituloModal('Vender esta Participación')
    setMensajeModal('¿Esta seguro que desea vender esta Participación?')
}

const ofertar = (data) => {
    setEsAceptada(true)
    setDataModal(data)
    setModalRetiro(true)
    setTituloModal('Ofertar esta Participación')
    setMensajeModal('¿Esta seguro que desea ofertar esta Participación?')
}

// const aceptarModal = (dataComision) => {
//   if (esAceptada) {
//     deleteOneSecondaryMarkets_Detail(dataComision)
//       .then(() => {
//         setModalRetiro(false);
//         window.location.reload(); 
//         window.location.href = '/secondary_markets';
//       })
//   } else {
//     deleteOneSecondaryMarkets_Detail(dataComision)
//       .then(() => {
//         setModalRetiro(false);
//         window.location.reload();
//         window.location.href = '/secondary_markets'; 
//       })
//   }
// };

function handleChange(event) {
  setValorInput(event.target.value);
}

const aceptarModal = (dataComision) => {
  if (esAceptada) {
    const data = {
      sale_id: id,
      nft_id: dataComision.id,
      price: valorInput,
    }
    addOneSecondaryMarkets_Detail(data)
    
      .then(() => {
        setModalRetiro(false);
        window.location.reload();
        // window.location.href = '/secondary_markets';
      })
  } else {
    deleteOneSecondaryMarkets_Detail(dataComision.id)
      .then(() => {
        setModalRetiro(false);
        window.location.reload();
        window.location.href = '/secondary_markets';
      })
  }
};

  return (
    <Layout
      title="Mercados secundarios"
      icon="icon_MisNftColor_icon w-30"
      backlink={"#"}
    >
      <div className="container-fluid pt-body">
        <Modal
          isOpen={modalRetiro}
          style={customStylesModalRetiro}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="modal_Box">
            <div className="row modal_header m-l-5 m-r-5">
              <div className="col-sm-11 p-l-0">
                <h2>{tituloModal}</h2>
              </div>
              <div className="col-sm-1 p-r-0">
                <div className="pull-right p-5px default-select bg-white b-none">
                  <div
                    className="personalizedicon mr-0 pt-0"
                    onClick={() => setModalRetiro(false)}
                  >
                    <div className="icon_Close_icon w-15"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row modal_body">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>{mensajeModal}</p>
                    {
                      mostrarVender ? 
                      <table className="table text-black">
                      {
                        <tr>
                          <td> Participación identificador: {dataModal.nft_id}</td>
                          <td>Precio : ${dataModal.price}</td>
                        </tr>
                      }
                    </table> :
                    <div className="d-flex justify-content-center m-t-20">
                      <input type="number" id="contratosCant" onChange={handleChange} className="form-control w-50  text-center" />
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="button-container">
            <button
              onClick={() => setModalRetiro(false)}
              type="button"
              className="col-xs-6 btn btn-rent-white"
            >
              Cancelar
            </button>
            <button
              onClick={() => aceptarModal(dataModal)}
              type="button"
              className="col-xs-6 btn btn-rent-white"
            >
              Aceptar
            </button>
          </div>
        </Modal>
      </div>
      <div className="row mb-2 container-fluid pt-body">
        <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
          <h2 className="font-w600 mr-auto ">
            <span className="mm-active">
              <div className="personalizedicon">
                {" "}
                <div className="icon_MisNftColor_icon w-30"></div>{" "}
              </div>
            </span>
            {"Mercados secundarios"}
          </h2>
        </div>

        <div className="row">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="card">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-xl-4 col-lg-5  col-md-5 col-xxl-4 ">
                      <Tab.Container defaultActiveKey="first">
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div className="contenedor">
                              <figure>
                                <img src={rData.file} className="img-fluid" />
                              </figure>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                        <div className="tab-slide-content new-arrival-product mb-4 mb-xl-0"></div>
                      </Tab.Container>
                      {/* <div className="comment-review star-rating d-flex m-t-10">
                        <div className="fs-15">
                          <div className="personalizedicon m-r-5">
                            {" "}
                            <div className="icon_UbicationColor_icon w-15"></div>{" "}
                          </div>{" "}
                          {franchise.name}
                        </div>
                      </div>
                      <div className="comment-review star-rating d-flex m-t-10">
                        <div className="fs-15">Propiedades:</div>
                        <ul className="m-l-10 p-l-0">{rData.status}</ul>
                      </div> */}
                      <div className="comment-review star-rating d-flex m-t-10">
                        <div className="fs-15">
                          <a
                            href={franchise.franchise_type}
                            target="_blank"
                            className="btn text-white m-r-10 btn-primary btn-rent-invert"
                          >
                            {" "}
                            Descargar Statement
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-7  col-md-7 col-xxl-8 col-sm-12">
                      <div className="product-detail-content">
                        <div className="new-arrival-content pr">
                          <div className="d-flex">
                            <h4 className="m-r-20">
                              {rData.description} 
                            </h4>
                          </div>
                          <table className="table m-t-30">
                              <tbody>
                                <tr>
                                  <td>
                                    Precio Actual:
                                  </td>
                                  <td>
                                    $ {marketData.current_value} USD
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Precio de venta:
                                  </td>
                                  <td>
                                    $ {marketData.sale_price} USD
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Participaciones en Venta: 
                                  </td>
                                  <td>
                                      {marketData.participaciones}
                                  </td>
                                </tr>
                              </tbody>
                          </table>

                          <p className="text-rent item text-white text-justify">
                            {/* {Parser(franchise.description)} */}
                          </p>
                          <div className="contenedor">
                            <div className="shopping-cart mt-30 d-flex">
                              {!mostrarVender ? (
                                <Button
                                  disabled={mostrarVender}
                                  onClick={() => ofertar(rData)}
                                  variant="link"
                                  className="justify-content-end btn text-white m-r-10 btn-primary btn-rent-invert"
                                >
                                  Ofertar
                                </Button>
                              ) : (
                                ""
                              )}
                              <Button
                                variant="link"
                                className="btn text-white m-r-10 btn-primary btn-rent-invert"
                                onClick={() => viewLocations(franchise.id)}
                              >
                                Ver Propiedades
                              </Button>
                              {/* <a href={franchise.seguimiento_link} target="_blank" className="btn text-white m-r-10 btn btn-rent-invert"> Seguimiento de las Propiedades</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="product-detail-content">
                        <div style={{ overflow: "auto", maxHeight: "560px" }}>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Comprador</th>
                                <th scope="col">Precio</th>
                                {
                                  mostrarVender ? <th scope="col"></th> : ''

                                }
                              </tr>
                            </thead>
                            <tbody>
                              {rDataTable?.map((data) => (
                                <tr>
                                  <td className="text-white">{data.name+' '+data.last_name}</td>
                                  <td className="text-white">{data.price}</td>
                                  {
                                    mostrarVender ? 
                                    <td className="text-white">
                                    <Button
                                      disabled={!mostrarVender}
                                      onClick={() => vender(data)}
                                      variant="link"
                                      className="btn text-white btn-primary btn-rent-invert"
                                    >
                                      Vender
                                    </Button>
                                  </td> : ''
                                  }
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card col-md-12">
                <div className="card-body ">
                  <h5 className="mb-30">Información financiera</h5>
                  <div>
                    <div className="">
                      <h5 className="mb-30 ">Datos de la franquicia</h5>
                      <div className="row">
                        <div className="col-md-10 ">
                          <div className="col-md-12 p-0">
                            Rentabilidad con alquiler
                            -----------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Rentabilidad con venta
                            -----------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Precio token
                            ------------------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Interés anual por token
                            -----------------------------------------
                          </div>
                          <div className="col-md-12 p-0 mb-3">
                            Tokens emitidos
                            -------------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Tipo de franquicia
                            -----------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            De vivienda
                            --------------------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Corporativa
                            -----------------------------------------------------
                          </div>
                          <div className="col-md-12 p-0 mb-3">
                            Servicio público
                            --------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            ¿Validación de la ESI?
                            -------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Documentación disponible:
                            ------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Documento de emisión
                            ------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Planos
                            ------------------------------------------------------------
                          </div>
                        </div>

                        <div className="col-md-2 p-0">
                          <div className="col-md-12 p-0">777775,84%</div>
                          <div className="col-md-12 p-0">2,84%</div>
                          <div className="col-md-12 p-0">33333,84%</div>
                          <div className="col-md-12 p-0">33333,84%</div>
                          <div className="col-md-12 p-0 mb-3">33333,84%</div>
                          <div className="col-md-12 p-0 ">33333,84%</div>
                          <div className="col-md-12 p-0">3,84%</div>
                          <div className="col-md-12 p-0">1,84%</div>
                          <div className="col-md-12 p-0 mb-3">2,84%</div>
                          <div className="col-md-12 p-0">31,84%</div>
                          <div className="col-md-12 p-0">31,84%</div>
                          <div className="col-md-12 p-0">22222,84%</div>
                          <div className="col-md-12 p-0">99,84%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card col-md-12">
                <div className="card-body ">
                  <h5 className="mb-30">Información financiera</h5>
                  <div>
                    <div className="">
                      <h5 className="mb-30 ">Datos de la franquicia</h5>
                      <div className="row">
                        <div className="col-md-10 ">
                          <div className="col-md-12 p-0">
                            Rentabilidad con alquiler
                            -----------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Rentabilidad con venta
                            -----------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Precio token
                            ------------------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Interés anual por token
                            -----------------------------------------
                          </div>
                          <div className="col-md-12 p-0 mb-3">
                            Tokens emitidos
                            -------------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Tipo de franquicia
                            -----------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            De vivienda
                            --------------------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Corporativa
                            -----------------------------------------------------
                          </div>
                          <div className="col-md-12 p-0 mb-3">
                            Servicio público
                            --------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            ¿Validación de la ESI?
                            -------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Documentación disponible:
                            ------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Documento de emisión
                            ------------------------------------------
                          </div>
                          <div className="col-md-12 p-0">
                            Planos
                            ------------------------------------------------------------
                          </div>
                        </div>

                        <div className="col-md-2 p-0">
                          <div className="col-md-12 p-0">777775,84%</div>
                          <div className="col-md-12 p-0">2,84%</div>
                          <div className="col-md-12 p-0">33333,84%</div>
                          <div className="col-md-12 p-0">33333,84%</div>
                          <div className="col-md-12 p-0 mb-3">33333,84%</div>
                          <div className="col-md-12 p-0 ">33333,84%</div>
                          <div className="col-md-12 p-0">3,84%</div>
                          <div className="col-md-12 p-0">1,84%</div>
                          <div className="col-md-12 p-0 mb-3">2,84%</div>
                          <div className="col-md-12 p-0">31,84%</div>
                          <div className="col-md-12 p-0">31,84%</div>
                          <div className="col-md-12 p-0">22222,84%</div>
                          <div className="col-md-12 p-0">99,84%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="product-detail-content">
                        <div className="new-arrival-content pr">
                          <div className="d-flex">
                            <h4 className="m-r-20">
                              Ubicación de las propiedades
                            </h4>
                          </div>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: franchise.google_map,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
function acceptComission(dataComision: any) {
  throw new Error("Function not implemented.");
}

function declineComission(dataComision: any) {
  throw new Error("Function not implemented.");
}

