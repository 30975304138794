import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setLocationsList, setLocationsMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getLocations } from "services/locationsService";
import { getOneFranchises } from "services/franchisesService";
import Layout from "template";
import { Constant } from "template/Constant";
import { LocationsForm } from "./form";
import { LocationsTable } from "./table";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom'

export const Locations: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  let uri = location.pathname;
  let parts = uri.split("/");
  let query = parts[2];
  // console.log(query);


  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.locations);
  const [franchise, setFranchise] = useState({'id':'', 'name':'', 'description':'', 'avatar':'', 'city':'', 'country':'', 'pricing_list':'', 'discounts':'', 'min_invest':'', 'expexted_profit':'', 'qualification':'', 'google_map':''});
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');

  const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
  const name = String(Object.values(userProfile)[1]);



  const getData = (page, pageSize, searchKey) => {

    let uri = location.pathname;
    let parts = uri.split("/");
    let query = parts[2];


    getLocations(page, pageSize, query).then((response) => {
      if (response && response.records) {
        dispatch(setLocationsList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.totalCount, searchKey: searchKey }));
      } else {
        dispatch(setLocationsMessage("No Record Found"));
      }
    })
  }

    useEffect(() => {

      getOneFranchises(query).then((response) => {
        setFranchise(response.data);
          return '';
      })
    },[])

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setLocationsMessage(''));
    setAction('edit');
  }
  const backlink = '/franchises_detail/'+query;
  return (
    <Layout title={franchise.name+'- Propiedades'}  icon="icon_DashboardColor_icon w-30" backlink={backlink} >
    <div className="container-fluid pt-body">
        <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
					  <h2 className="font-w600 mr-auto "> 
						  <span className="mm-active">
                  <div className="personalizedicon"> <div className="icon_DashboardColor_icon w-30"></div> </div> 
              </span> 
              {franchise.name} {'- Propiedades'}
					</h2>
        </div>

        <div className="d-flex flex-column min-vh-100">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setLocationsMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
          {action ? <LocationsForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
            <LocationsTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />}
        </div>

      </div>
    </Layout >
  );
};

