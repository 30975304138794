import { APIService } from "services";

export const getAllOffertsMine = () => {
    return APIService.api().get(`/nft_market/getmarketbyuser/mine`);
  };

export const getAllOffertsSent = () => {
    return APIService.api().get(`/nft_market/getmarketbyuser/sent`);
  };

export const getTableOfferts_Detail = () => {
    return APIService.api().get(`/nft_offers`)  ///search/:searchKey
}

export const cancelOfert = (data, id) => {
  
  return APIService.api().delete(`/nft_offers/${id}`, data)
}

export const AcceptOfert = (id) => {
  return APIService.api().get(`/nft_offers/sell/${id}`)
}
