import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { getParticipations } from "services/nftsService";
import { setNft_FavoritesList, setNft_FavoritesMessage } from "redux/actions";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getNft_Favorites } from "services/nft_favoritesService";
import { Constant } from "template/Constant";
// import Grahp3Graph from './Pie5';
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";

 const Grahp3Graph = loadable(() =>
 	pMinDelay(import("./Pie5"), 1000)
 );

export const Grahp3: React.FC = () => {
  const [totalFranchises, settotalFranchises] = useState(0);
  const [labels, setLabels] = useState([]);
  const [totales, setTotales] = useState([]);
  const [rtotales, setRTotales] = useState('') ;
  const [rLabels, setRLabels] = useState('') ;
  

  useEffect(() => {
    getParticipations().then((response) => {
        response.data.records.map((e) => {
              let elm = e.total;

              let titulo = e.description;
            // ASIGNANDO TOTALES
              setTotales(totales => [...totales, elm ]);

            // ASIGNANDO TITULOS
              setLabels(labels => [...labels, titulo ]);
          })
        })

  },[]);
  
  // ASIGNANDO LABELS
  useEffect(() => {
    setRLabels("["+labels+"]");
  },[labels]);

// ASIGNANDO VALORES
  useEffect(() => {
    setRTotales("["+totales+"]");
  },[totales]);

  return (
      <div id="chart" className="apex-chart">
            <Grahp3Graph totales={rtotales != '' ? rtotales:'[]'} labels={rLabels != '' ? rLabels:'[]'} />
      </div>
    );
  
}