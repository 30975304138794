
import { APIService } from "services";

export const tokenAPICALL = async (user, pass) => {
try{
    const response = await APIService.apiNoAuth().post(`/token`, {
        "username": user,
        "password": pass
    }, {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        }
    })

    if (response && response.status === 200) {
        const data = response.data;
        const now = new Date().getTime();
        const expiryDate = now + data.expires_in;
        return { jwtToken: data.access_token, expiryDate: expiryDate,userData: data.userData };
    } else {
        return null;
    }
    }catch(error){
    console.log("Login error",error);
    return null;
    }
}

export const userRegister = async(username, password, name, lastname, parent_code, sexo, birthday, repeatPassword, phone) => {
    try{
        const response = await APIService.apiNoAuth().post(`/register`, {

            "username": username,
            "password": password,
            "name": name,
            "lastname": lastname,
            "parent_code": parent_code,
            "sexo": sexo,
            "birthday": birthday,
            "repeatPassword": repeatPassword,
            "phone": phone,

        }, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
    
        if (response && response.status === 200) {
            const data = response.data;
            const now = new Date().getTime();
            const expiryDate = now + data.expires_in;
            return { jwtToken: data.access_token, expiryDate: expiryDate,userData: data.userData };
        } else {
            return null;
        }
    }catch(error){
        console.log("Register error",error);
        return null;
    }
}