import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ILocations {
id:number,
franchise_id?:string,
name?:string,
description?:string,
address?:string,
city?:string,
country?:string,
latitude?:string,
longitude?:string,
property_type?:string,
units?:string,
mts?:string,
estimated_rent?:string,
rental_increment?:string,
image?:string,
created_at?:Date
}

interface ILocationsData {
    list?: Array<ILocations>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ILocationsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const locationsSlice = createSlice({
    name: "locations",
    initialState,
    reducers: {
        setLocationsList: (state, _action: PayloadAction<ILocationsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetLocationsToInit: (state) => {
            state = initialState;
        },
        setLocationsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setLocationsList, resetLocationsToInit, setLocationsMessage } = locationsSlice.actions;

export default locationsSlice.reducer;

