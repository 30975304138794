import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setFranchises_DetailMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addFranchises_Detail, updateFranchises_Detail } from "services/franchises_detailService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Franchises_DetailForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',franchise_id:'',profit_rent:'',sell_rent:'',token_price:'',int_token:'',total_tokens:'',esi_validation:'',documentation:'',doc_emision:'',plans:'',created_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateFranchises_Detail(values.id,values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setFranchises_DetailMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setFranchises_DetailMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addFranchises_Detail(values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setFranchises_DetailMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setFranchises_DetailMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           franchise_id: yup.string().required('franchise_id is required'),
profit_rent: yup.string(),
sell_rent: yup.string(),
token_price: yup.string(),
int_token: yup.string(),
total_tokens: yup.string(),
esi_validation: yup.string(),
documentation: yup.string(),
doc_emision: yup.string(),
plans: yup.string(),
created_at: yup.date(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Franchises_Detail
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Franchises_Detail</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">franchise_id</label>
<Form.Control type="text" name="franchise_id" className="form-control" value={formik.values.franchise_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.franchise_id && !!formik.errors.franchise_id}
isValid ={!!formik.touched.franchise_id && !formik.errors.franchise_id}
></Form.Control>
{
    formik.errors.franchise_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.franchise_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">profit_rent</label>
<Form.Control type="text" name="profit_rent" className="form-control" value={formik.values.profit_rent}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.profit_rent && !!formik.errors.profit_rent}
isValid ={!!formik.touched.profit_rent && !formik.errors.profit_rent}
></Form.Control>
{
    formik.errors.profit_rent && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.profit_rent}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">sell_rent</label>
<Form.Control type="text" name="sell_rent" className="form-control" value={formik.values.sell_rent}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.sell_rent && !!formik.errors.sell_rent}
isValid ={!!formik.touched.sell_rent && !formik.errors.sell_rent}
></Form.Control>
{
    formik.errors.sell_rent && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.sell_rent}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">token_price</label>
<Form.Control type="text" name="token_price" className="form-control" value={formik.values.token_price}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.token_price && !!formik.errors.token_price}
isValid ={!!formik.touched.token_price && !formik.errors.token_price}
></Form.Control>
{
    formik.errors.token_price && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.token_price}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">int_token</label>
<Form.Control type="text" name="int_token" className="form-control" value={formik.values.int_token}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.int_token && !!formik.errors.int_token}
isValid ={!!formik.touched.int_token && !formik.errors.int_token}
></Form.Control>
{
    formik.errors.int_token && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.int_token}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">total_tokens</label>
<Form.Control type="text" name="total_tokens" className="form-control" value={formik.values.total_tokens}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.total_tokens && !!formik.errors.total_tokens}
isValid ={!!formik.touched.total_tokens && !formik.errors.total_tokens}
></Form.Control>
{
    formik.errors.total_tokens && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.total_tokens}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">esi_validation</label>
<Form.Control type="text" name="esi_validation" className="form-control" value={formik.values.esi_validation}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.esi_validation && !!formik.errors.esi_validation}
isValid ={!!formik.touched.esi_validation && !formik.errors.esi_validation}
></Form.Control>
{
    formik.errors.esi_validation && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.esi_validation}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">documentation</label>
<Form.Control type="text" name="documentation" className="form-control" value={formik.values.documentation}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.documentation && !!formik.errors.documentation}
isValid ={!!formik.touched.documentation && !formik.errors.documentation}
></Form.Control>
{
    formik.errors.documentation && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.documentation}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">doc_emision</label>
<Form.Control type="text" name="doc_emision" className="form-control" value={formik.values.doc_emision}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.doc_emision && !!formik.errors.doc_emision}
isValid ={!!formik.touched.doc_emision && !formik.errors.doc_emision}
></Form.Control>
{
    formik.errors.doc_emision && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.doc_emision}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">plans</label>
<Form.Control type="text" name="plans" className="form-control" value={formik.values.plans}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.plans && !!formik.errors.plans}
isValid ={!!formik.touched.plans && !formik.errors.plans}
></Form.Control>
{
    formik.errors.plans && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.plans}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="date" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

