import React, { useState, useEffect, useContext, createContext } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from "redux/store";
import { getOneFranchises, getPurchaseStatus } from "services/franchisesService";
import Parser from 'html-react-parser';
import franchises_detail from "redux/slices/franchises_detail";
import { useNavigate } from "react-router";
import { addNfts, updateNfts } from "services/nftsService";
import logo from '../../assets/images/rent/RentLogoBlack_icon.svg';

export const PurchaseConfirmation: React.FC = () => {

    
    const location = useLocation();
    let uri = location.pathname;
    const query = location.search;
    let parts = query.split("&");

    const arrayresourceCode = parts[0].split("=");
    const arraytransactionStatus = parts[1].split("=");
    
    const resourceCode = arrayresourceCode[1];
    const transactionStatus = arraytransactionStatus[1];
    const [action, setAction] = useState({amount_in_cents:'', created_at:'', currency:'', id:'', transaction_id:'', type:'', redirect_url:'', reference:'', status:''});

 

    useEffect(() => {
        getPurchaseStatus(resourceCode).then((response) => {
            setAction(response);
            console.log(action);
        })
    },[])


    

    return (
        <div className="container-fluid LoginBp">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center vh-100">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="card o-hidden border-0 bg-white shadow-lg">
                            <div className="card-body p-0 login-card">
                                <div className="row">
                                    <div className="col-lg-12 col-md-offset-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <img src={logo} alt="logo" className="logo" />
                                            </div>
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Gracias por tu compra</h1>
                                            </div>
											<p className="text-gray-900 text-center">
												Estarémos notificandote vía e-mail en cuanto el proceso se haya validado.
                                                
											</p>
                                            <p className="text-gray-900">Transacción ID: {resourceCode}</p>
                                            <p className="text-gray-900">Transaction Enviroment: {transactionStatus}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
};
