import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetLocationsToInit, setLocationsMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteLocations } from "services/locationsService";
import { getOneFranchises } from "services/franchisesService";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom'
import SimpleImageSlider from "react-simple-image-slider";


type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const LocationsTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {

    const location = useLocation();
    const navigate = useNavigate();
    let uri = location.pathname;
    let parts = uri.split("/");
    let query = parts[2];
    // console.log(query);

    const styles = {
        cover : {
            width:'100%',
            height:'270px',
            backgroundSize: 'contain !important', 
            backgroundPosition: 'center !important', 
            backgroundRepeat: 'no-repeat !important'
            
        }
    }


    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const [franchise, setFranchise] = useState({'id':'', 'name':'', 'description':'', 'avatar':'', 'city':'', 'country':'', 'pricing_list':'', 'discounts':'', 'min_invest':'', 'expexted_profit':'', 'qualification':'', 'google_map':''});
    const rData = useSelector((state: RootState) => state.locations);

    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
    const name = String(Object.values(userProfile)[1]);

    const functionButtons = (row) => {

        if(String(Object.values(userProfile)[6]) == "1"){
            return (
                <>
                    <Button variant="link" className="btn-sm text-white btn-info m-r-20" onClick={() => handleRowEdit(row)}>Edit</Button>
                    <Button variant="link" className="btn-sm text-white btn-danger" onClick={() => handleRowDeleteClick(row)}>Delete</Button>
                </>
            )
        }else{
            return "";
        }

    }


    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetLocationsToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    }, [rData.list.length])
    const handleReset = () => {
        dispatch(resetLocationsToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }

    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteLocations(rowData.id);
            if (response) {
                dispatch(resetLocationsToInit());
                dispatch(setLocationsMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setLocationsMessage("Some error occured!"));
            }
        }
    }


    const [state, setState] = useState({ isOpen: false });
    const [imageFile, setImageFile] = useState("");
    const [descriptionFile, setDescriptionFile] = useState("");
    const handleShowDialog = (img, description) => {
        setImageFile(img);
        setDescriptionFile(description);
        setState({ isOpen: !state.isOpen });
        console.log("cliked");

        const vector = img.split(",");
        let listado = [];
        vector.map((e) => {
            const im = { url: e}
            listado.push(im);
        });
        console.log(listado);
        setImages(listado);


    };


    useEffect(() => {

        getOneFranchises(query).then((response) => {
            setFranchise(response.data);
            return '';
        })
    },[])
    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setLocationsMessage(''));
        hideShowForm('add');
    }

    const columns = [
        {name: 'id', selector: row => row.id, sortable: true},
{name: 'franchise_id', selector: row => row.franchise_id, sortable: true},
{name: 'name', selector: row => row.name, sortable: true},
{name: 'description', selector: row => row.description, sortable: true},
{name: 'address', selector: row => row.address, sortable: true},
{name: 'city', selector: row => row.city, sortable: true},
{name: 'country', selector: row => row.country, sortable: true},
{name: 'latitude', selector: row => row.latitude, sortable: true},
{name: 'longitude', selector: row => row.longitude, sortable: true},
{name: 'property_type', selector: row => row.property_type, sortable: true},
{name: 'units', selector: row => row.units, sortable: true},
{name: 'mts', selector: row => row.mts, sortable: true},
{name: 'estimated_rent', selector: row => row.estimated_rent, sortable: true},
{name: 'rental_increment', selector: row => row.rental_increment, sortable: true},
{name: 'image', selector: row => row.image, sortable: true},
{name: 'created_at', selector: row => row.created_at, sortable: true},

        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];

    const cover = (list) => {

        const vector = list.split(",");
        return vector[0];
    }

    const [images, setImages] = useState([]);
    
    const CSSProperties = {}
    return (<>
            {/* { buttonAdd } */}
            <div className="row">
                <div className="col-lg-12 m-b-30">
                    {/* <Button className="btn-icon-split float-right btn-rent-invert b-0" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        <span className="text">Add New</span>
                    </Button> */}
                </div>
            </div>

            <div className="row">
            {
                rData.list.map( (r, i) => (<>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div className="card">
                            <div className="card-body p-30">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12 ">
                                        <div className="contenedor m-b-20">
                                            <figure>
                                                <img 
                                                    src={cover(r.image)} 
                                                    className="img-fluid" 
                                                    style={ styles.cover } 
                                                    onClick={() =>  handleShowDialog(r.image, r.name)}
                                                    alt="no image"
                                                />
                                            </figure>
                                        </div>
                                        <p className="text-rent"> Propiedad:{" "}<span className="item text-white"> {r.name}</span></p>
                                        <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_UbicationColor_icon w-15"></div> </div> LLC:{" "}<span className="item text-white"> {" "}{franchise.name}</span></p>
                                        {/* <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_PropiedadesColor_icon w-15"></div> </div> Caracteristicas: <span className="item text-white">{r.description}</span></p> */}
                                        {functionButtons(r)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                ))    
            }
            </div>
            {state.isOpen && (
        <dialog
            className="dialog"
            open
        >
            
            <SimpleImageSlider
                width={896}
                height={504}
                images={images}
                style={{backgroundSize: 'contain !important', backgroundPosition: 'center !important', backgroundRepeat: 'no-repeat !important'}}
                showBullets={true}
                showNavs={true}
            />
            <p className="text-rent m-t-10"> 
                <div className="personalizedicon m-r-5"> <div className="icon_UbicationColor_icon w-15"></div> 
                </div>
                {descriptionFile}
            </p>
            <a href="#" onClick={() => handleShowDialog("", "")} className="text-white">Cerrar</a>
        </dialog>
        )}
            <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        </>
    );
}

