import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import { deleteReferido, getComissionData } from "services/comissionService";
import Layout from "template";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    iconForm:{
        marginRight: '10px',
    },
    formRegister:{
        width:'85%',
    },
    colorSVG:{
        color:'#FFFFFF',
    }
};

const customStylesModal = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      height: '250px',
      transform: 'translate(-50%, -50%)',
    },
};

const customStylesModalRetiro = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      height: '450px',
      transform: 'translate(-50%, -50%)',
    },
};

export const Commission: React.FC = () => {

    const VALOR_MONTO_TOTAL = 250

    const [modalRetiroMinimo, setModalRetiroMinimo] = useState(false);
    const [modalRetiro, setModalRetiro] = useState(false);
    const [modalSolicitudRetiro, setModalSolicitudRetiro] = useState(false);
    const [disabledRetiro, setDisabledRetiro] = useState(true);
    const [totalDisponible, setTotalDisponible] = useState(0);
    const [dataEnviar, setDataEnviar] = useState({ comission_list: [], total_value: null, payed_by: '' });
    const [rData, setRData] = useState([]);
    const [totalReferidos, setTotalReferidos] = useState(0);
    const [checkedState, setCheckedState] = useState([]);
    const [totalGanado, setTotalGanado] = useState(0);
    const [totalRetirado, setTotalRetirado] = useState(0);

    useEffect(() => {
        getComissionData().then((response) => {
            const dataRecords = response.data.records
            setTotalReferidos(response.data.totalCount)
            setTotalDisponible(dataRecords.reduce((acomulador, currentValue) => acomulador + currentValue.price, 0))
            setRData(dataRecords)
            setTotalGanado(response.data.totales ? response.data.totales : 0)
            setTotalRetirado(response.data.totalPagado ? response.data.totalPagado : 0)
            setCheckedState(new Array(dataRecords.length).fill(false))
        })
    }, [])

    const onClickRetiro = async () => {
        await eliminarRegistro()
        setRData(rData)
        setModalRetiro(false)
        setModalSolicitudRetiro(true)
    }

    const eliminarRegistro = async () => {
        let conteoFor = 0
        rData.forEach((_, index) => {
            if (checkedState[index]) {
                rData.splice(index - conteoFor, 1)
                conteoFor++
            }
        })
        const filterChecks = checkedState.filter((check) => !check)
        setCheckedState(filterChecks)
    }
    
    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) => index === position ? !item : item);
        setCheckedState(updatedCheckedState);
        setDisabledRetiro(!updatedCheckedState.some((check) => check))
        let newValorTotal = totalDisponible
        rData.forEach((referido, index) => {
            if (updatedCheckedState[index] && position === index) {
                newValorTotal -= referido.price
                setTotalDisponible(newValorTotal)
            } else if (!updatedCheckedState[index] && position === index) {
                newValorTotal += referido.price
                setTotalDisponible(newValorTotal)
            }
        })
    }

    const handleOnChangeRetirar = async () => {
        const dataEnviar = await enviarData()
        const valorMonto = dataEnviar.comission_list.reduce((acomulador, currentValue) => acomulador + currentValue.valor, 0 )
        if (valorMonto >= VALOR_MONTO_TOTAL) {
            return setModalRetiro(true)
        }
        setModalRetiroMinimo(true)
    }

    const enviarData = async () => {
        const dataEnviar = { comission_list: [], total_value: null, payed_by: '' }
        rData.forEach((referido, index) => {
            if (checkedState[index]) {
                setTotalRetirado(totalRetirado + referido.price)
                dataEnviar.comission_list.push({ numeroReferido: referido.id,
                    valor: referido.price,
                    referido: referido.name
                 })
                 dataEnviar.total_value = referido.price + dataEnviar.total_value
            }
        })
        setDataEnviar(dataEnviar)
        const valorRetirar = dataEnviar.comission_list.reduce((acomulador, referido) => acomulador + referido.valor, 0)
        setTotalRetirado(valorRetirar + totalRetirado)
        return dataEnviar
    }

    const handleEliminarReferidos = () => {
        setTotalReferidos(rData.length)
        setDisabledRetiro(true)
        deleteReferido(dataEnviar).then((_) => {
            setModalSolicitudRetiro(false)
        })
        .catch(error)
    }

    const error = () => {
        console.log('Hubo un error, vuelve a intentar mas tarde')
    }

    const convertirFecha = (fecha) => {
        const date = new Date(fecha);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
        return formattedDate
    }

    return (
        <Layout title="Comisiones"  icon="icon_MisNftColor_icon w-30" backlink={"#"} >
            <div className="container-fluid pt-body">
                <Modal
                    isOpen={modalRetiroMinimo}
                    style={customStylesModal}
                    ariaHideApp={false}
                    contentLabel="Example Modal">
                    <div className="modal_Box">
                        <div className="row modal_header m-l-5 m-r-5">
                            <div className="col-sm-11 p-l-0">
                                <h2>Retiro mínimo de comisiones</h2>
                            </div>
                            <div className="col-sm-1 p-r-0">
                                <div className="pull-right p-5px default-select bg-white b-none">
                                    <div className="personalizedicon mr-0 pt-0" onClick={() => setModalRetiroMinimo(false)}>
                                        <div className="icon_Close_icon w-15"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row modal_body">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <p>
                                        El retiro de las comisiones debe ser minimo $250 USDC
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => setModalRetiroMinimo(false)} type="button" className="btn btn-rent-white-modal mt-50 mb-50">Entendido</button>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="container-fluid pt-body">
                <Modal
                    isOpen={modalRetiro}
                    style={customStylesModalRetiro}
                    ariaHideApp={false}
                    contentLabel="Example Modal">
                    <div className="modal_Box">
                        <div className="row modal_header m-l-5 m-r-5">
                            <div className="col-sm-11 p-l-0">
                                <h2>Retiro de comisiones</h2>
                            </div>
                            <div className="col-sm-1 p-r-0">
                                <div className="pull-right p-5px default-select bg-white b-none">
                                    <div className="personalizedicon mr-0 pt-0" onClick={() => setModalRetiro(false)}>
                                        <div className="icon_Close_icon w-15"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row modal_body">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <p>
                                            A continuación la descripción de las comisiones que has
                                            solicitado retirar (solo disponible en Colombia; retiro en
                                            cuenta bancaria local):
                                        </p>
                                        <table className="table text-black"> 
                                            {
                                                dataEnviar?.comission_list?.map((data) => (
                                                    <tr>
                                                        <td>{data.referido}</td>
                                                        <td>$ {data.valor}</td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-container">
                            <button onClick={() => setModalRetiro(false)} type="button" className="col-xs-6 btn btn-rent-white">Cancelar</button>
                            <button onClick={() => onClickRetiro()} type="button" className="col-xs-6 btn btn-rent-white">Retirar</button>
                    </div>
                </Modal>
            </div>
            <div className="container-fluid pt-body">
                <Modal
                    isOpen={modalSolicitudRetiro}
                    style={customStylesModal}
                    ariaHideApp={false}
                    contentLabel="Example Modal">
                    <div className="modal_Box">
                        <div className="row modal_header m-l-5 m-r-5">
                            <div className="col-sm-11 p-l-0">
                                <h2>Solicitud retiro</h2>
                            </div>
                            <div className="col-sm-1 p-r-0">
                                <div className="pull-right p-5px default-select bg-white b-none">
                                    <div className="personalizedicon mr-0 pt-0" onClick={() => setModalSolicitudRetiro(false)}>
                                        <div className="icon_Close_icon w-15"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row modal_body">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <p>
                                        Tu solicitud de retiro será revisada y posteriormente depositada en tu Wallet
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => handleEliminarReferidos()} type="button" className="btn btn-rent-white-modal mt-50 mb-50">Entendido</button>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="container-fluid pt-body">
                <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                    <h2 className="font-w600 mr-auto "> 
                        <span className="mm-active">
                            <div className="personalizedicon"> <div className="icon_MisNftColor_icon w-30"></div> </div> 
                        </span> 
                            {'Comisiones'}
                    </h2>
                    <div>
                        <span className="btn text-white m-r-30 btn-rent-invert-comission">
                            <div className="personalizedicon hidden-xs"  style={customStyles.iconForm}>
                                <div className="icon_Usuario_icon_white w-30" style={customStyles.colorSVG}></div>
                            </div>
                            <span className="item text-white">Total referidos</span>
                            <div><span className="d-block fs-15 text-white">{totalReferidos}</span></div>
                        </span>
                        <span className="btn text-white m-r-30 btn-rent-invert-comission">
                            <div className="personalizedicon hidden-xs"  style={customStyles.iconForm}>
                                <div className="icon_Usuario_icon_white w-30" style={customStyles.colorSVG}></div>
                            </div>
                            <span className="item text-white">Total ganados</span>
                            <div><span className="d-block fs-15 text-white">${totalGanado} USD</span></div>
                        </span>
                        <span className="btn text-white m-r-30 btn-rent-invert-comission">
                            <div className="personalizedicon hidden-xs"  style={customStyles.iconForm}>
                                <div className="icon_Usuario_icon_white w-30" style={customStyles.colorSVG}></div>
                            </div>
                            <span className="item text-white">Total retirado</span>
                            <div><span className="d-block fs-15 text-white">${totalRetirado} USDC</span></div>
                        </span>
                        <span className="btn text-white m-r-30 btn-rent-invert-comission">
                            <div className="personalizedicon hidden-xs"  style={customStyles.iconForm}>
                                <div className="icon_Usuario_icon_white w-30" style={customStyles.colorSVG}></div>
                            </div>
                            <span className="item text-white">Total disponible</span>
                            <div><span className="d-block fs-15 text-white">${totalDisponible} USDC</span></div>
                        </span>
                    </div>
                </div>
            </div>
                <div className="card-body pb-0" style={{ maxHeight: "520px", overflow: "auto" }}>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="center-th-td"># de referido</th>
                        <th className="center-th-td">Referido</th>
                        <th className="center-th-td">Fecha ingreso</th>
                        <th className="center-th-td">Inversión</th>
                        <th className="center-th-td">Comisión total</th>
                        <th className="center-th-td">Comisión retirada</th>
                        <th className="center-th-td">Comisión disponible</th>
                        <th className="center-th-td">
                        <button disabled={disabledRetiro} onClick={() => handleOnChangeRetirar()} type="button" className="btn text-white btn-outline-info">Retirar</button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {rData?.map((event, index) => (
                        <tr key={index}>
                        <td className="center-th-td text-white">{event.id}</td>
                        <td className="center-th-td text-white">{event.name}</td>
                        <td className="center-th-td text-white">{convertirFecha(event.created_at)}</td>
                        <td className="center-th-td text-white">$ {event.inversion ? event.inversion : 0}</td>
                        <td className="center-th-td text-white">$ {event.owner}</td>
                        <td className="center-th-td text-white">$ {event.referral}</td>
                        <td className="center-th-td text-white">$ {event.price}</td>
                        <td className="center-th-td">
                            <input
                            type="checkbox"
                            id={`check${index}`}
                            name={`check${index}`}
                            value="retirar"
                            checked={checkedState[index]}
                            onChange={() => handleOnChange(index)}
                            />
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </div>
        </Layout >
      );
}
