import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setNft_OwnersMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addNft_Owners, updateNft_Owners } from "services/nft_ownersService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Nft_OwnersForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',nft_id:'',user_id:'',status:'',buy_price:'',sell_price:'',created_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateNft_Owners(values.id,values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setNft_OwnersMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setNft_OwnersMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addNft_Owners(values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setNft_OwnersMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setNft_OwnersMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           nft_id: yup.string().required('nft_id is required'),
user_id: yup.string().required('user_id is required'),
status: yup.number().required('status is required'),
buy_price: yup.string().required('buy_price is required'),
sell_price: yup.string(),
created_at: yup.date().required('created_at is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Nft_Owners
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Nft_Owners</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">nft_id</label>
<Form.Control type="text" name="nft_id" className="form-control" value={formik.values.nft_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nft_id && !!formik.errors.nft_id}
isValid ={!!formik.touched.nft_id && !formik.errors.nft_id}
></Form.Control>
{
    formik.errors.nft_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nft_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control type="text" name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
></Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">status</label>
<Form.Control type="number" name="status" className="form-control" value={formik.values.status}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.status && !!formik.errors.status}
isValid ={!!formik.touched.status && !formik.errors.status}
></Form.Control>
{
    formik.errors.status && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.status}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">buy_price</label>
<Form.Control type="text" name="buy_price" className="form-control" value={formik.values.buy_price}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.buy_price && !!formik.errors.buy_price}
isValid ={!!formik.touched.buy_price && !formik.errors.buy_price}
></Form.Control>
{
    formik.errors.buy_price && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.buy_price}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">sell_price</label>
<Form.Control type="text" name="sell_price" className="form-control" value={formik.values.sell_price}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.sell_price && !!formik.errors.sell_price}
isValid ={!!formik.touched.sell_price && !formik.errors.sell_price}
></Form.Control>
{
    formik.errors.sell_price && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.sell_price}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="date" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

