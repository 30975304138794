import React, { useState, useEffect, useContext, createContext } from "react";
import { CartDispatchContext, addToCart } from "../../contexts/cart";
import { Alert, Button, Card, Col, Form, InputGroup,Tab, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setNftsList, setNftsMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getOwnsNfts } from "services/nftsService";
import Layout from "template";
import { Constant } from "template/Constant";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router";
// import { getFranchises_Detail } from "services/franchises_detailService";
import { getOneNfts } from "services/nftsService";
import { setFranchises_DetailList, setFranchises_DetailMessage } from "redux/actions";
import { getFranchises_Detail } from "services/franchises_detailService";
import { getOneFranchises } from "services/franchisesService";
import { Franchises_DetailForm } from "../franchises_detail/form";
import { Franchises_DetailTable } from "../franchises_detail/table";
import Parser from 'html-react-parser';

export const View_Nft: React.FC = () => {

  const location = useLocation();
    const navigate = useNavigate();
    const dp = useContext(CartDispatchContext);
    let uri = location.pathname;
    let parts = uri.split("/");
    const query = parts[2];

    
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.nfts);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const [nft, setNft] = useState({'file': '','contact': '','description': '','author': '','owner': '','status': '','views': '','favorites': '','price': '','sale_date': '','contact_address': '','token_id': '','token_standard': '','blockchain': '','metadata': '','franchise_id': '','created_at': '','updated_at': '','deleted_at': ''});
  const [franchise, setFranchise] = useState({'id':'', 'name':'', 'description':'', 'avatar':'', 'city':'', 'country':'', 'pricing_list':'', 'discounts':'', 'min_invest':'', 'expexted_profit':'', 'qualification':'', 'google_map':'', 'rents':'', 'nft_description':''});
  const [data, setData] = useState({'id':'', 'name':'', 'price':'', 'image':''});

  const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
  const name = String(Object.values(userProfile)[1]);

  const getData = (page, pageSize, searchKey) => {
        
      let query = nft.franchise_id;

      getFranchises_Detail(page, pageSize, query).then((response) => {
          if (response && response.records) {
              dispatch(setFranchises_DetailList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.totalCount, searchKey: searchKey }));
          } else {
              dispatch(setFranchises_DetailMessage("No Record Found"));
          }
      })
  }


  useEffect(() => {

    getOneNfts(query).then((response) => {
        setNft(response.data);
        getOneFranchises(response.data.franchise_id).then((response) => {
          setFranchise(response.data);            
        })
    })


  },[]);

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setFranchises_DetailMessage(''));
    setAction('edit');
  }

  const Stars = (num) => {       
      num = parseInt(num);
      var items = "";

      for (var k = 0; k < num; k++) {
          items += '<div className="personalizedicon m-r-5"><div className="icon_PropiedadesColor_icon w-10"></div></div> ';
      }
      return items;
  }

  
  const viewLocations = (path) => {
      const newpath = '/locations/'+path;
      navigate(newpath, { replace: true });
  }
  
  console.log( "->", nft);
  const backlink = '/franchises';

  return (
    <Layout title={"Nft: " + nft.description}  icon="icon_MisNftColor_icon w-30" backlink={backlink} >
      <div className="container-fluid pt-body">
          <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
					  <h2 className="font-w600 mr-auto "> 
						  <span className="mm-active">
                  <div className="personalizedicon"> <div className="icon_MisNftColor_icon w-30"></div> </div> 
              </span> 
              {"Nft: " + nft.description}
					</h2>
        </div>
        <div className="row">                
              <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="card">
                      <div className="card-body p-30">
                          <div className="row">
                              <div className="col-xl-6 col-lg-6  col-md-6 col-xxl-6 ">
                              {/* Tab panes */}
                                <Tab.Container defaultActiveKey="first">
                
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className="contenedor">
                                                <figure>
                                                    <img src={franchise.avatar} className="img-fluid" />
                                                    {/* <div className="capa"></div> */}
                                                </figure>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
                                        
                                    </div>
                                </Tab.Container>
                                <div className="comment-review star-rating d-flex m-t-10">
                                    <div className="fs-15">
                                        <div className="personalizedicon m-r-5"> <div className="icon_UbicationColor_icon w-15"></div> </div> {franchise.name} ({franchise.city}), {franchise.country}
                                    </div>
                                </div>
                                <div className="comment-review star-rating d-flex m-t-10">
                                    <div className="fs-15">
                                        Propiedades:
                                    </div>
                                    <ul className="m-l-10 p-l-0">
                                        {Parser(Stars(franchise.qualification))}
                                    </ul>
                                </div>
                            </div>
                        {/*Tab slider End*/}

                        <div className="col-xl-6 col-lg-6  col-md-6 col-xxl-6 col-sm-12">
                          <div className="product-detail-content">
                              {/*Product details*/}
                              <div className="new-arrival-content pr">
                              <div className="d-flex">
                                  <h4 className="m-r-20">{franchise.name} ({franchise.city}), {franchise.country}</h4>
                              </div>
                              <p className="text-rent item text-white text-justify">{Parser(franchise.description)}</p>
                                  

                                  <div className="contenedor">
                                      <div className="shopping-cart mt-30 d-flex">
                                        <Button variant="link" className="btn text-white m-r-10 btn-primary btn-rent-invert" onClick={() => viewLocations(franchise.id)}>Ver Propiedades</Button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="card">
                      <div className="card-body p-30">
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="product-detail-content">
                                      <div className="new-arrival-content pr">
                                          <div className="d-flex">
                                              <h4 className="m-r-20">Ubicación de las propiedades</h4>
                                          </div>
                                      </div>
                                      <div dangerouslySetInnerHTML={{ __html: franchise.google_map}} />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

        </div>


        <div className="row">                
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex flex-column ">
                {rData.message ?
                    <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
                    <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setFranchises_DetailMessage(''))}>
                        <span aria-hidden="true">&times;</span>
                    </Button>
                    </Alert> : null}
                {action ? <Franchises_DetailForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
                    <Franchises_DetailTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
                <div className="card">
                    <div className="card-body p-30">                    
                        <h3>Rentabilidad:</h3>
                    </div>
                </div>
            </div>
        </div> 

      </div>
    </Layout >
  );
};

