import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useAppDispatch } from "redux/store";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { validateCode, forwardCode } from "services/usersService";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import { useFormik } from 'formik';
import * as yup from 'yup';
import {ChangePasswordVerificationCode} from './changePasswordVerificationCodeModal';

export const ForgotPassword: React.FC<{letmodal: boolean}> = ({letmodal}) => {

    const navigate = useNavigate();    


    useEffect(() => {
        // alert(letmodal);
        setIsOpen(letmodal);
    }, [letmodal])
    
    

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          height: '340px',
          transform: 'translate(-50%, -50%)',
        },
    };
   
    const [email, setEmail] = useState("");
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const forward = async () => {
        try{
            const response = await forwardCode(formik.values.email);
            if (response && (response.status === 200 || response.status === 201)) {
                setvalidateResponse("messange sent");
                setopenModal(true);
                setIsOpen(false);
            } else {
                setvalidateResponse("messange un sent");
            }
        }catch(error){
            console.log("error", error);
        }
    }

    const formik = useFormik({
        initialValues: {
            email: ''
        },

        onSubmit: async (values) => {
            const code = formik.values.email;
            
        },
    });
    const [validateResponse, setvalidateResponse] = useState('');
    Modal.setAppElement('#root');

    useEffect(() => {
        document.body.classList.toggle('LoginBp', true);
    }, [])

    const [openModal, setopenModal] = useState(false);

    return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="modal_Box">
                <div className="row modal_header m-l-5 m-r-5">
                    <div className="col-sm-11 p-l-0">
                        <h2>Confirmación de correo</h2>
                    </div>
                    <div className="col-sm-1 p-r-0">
                        <div className="pull-right p-5px default-select bg-white b-none">
                            <div className="personalizedicon mr-0 pt-0" onClick={closeModal}>
                                <div className="icon_Close_icon w-15"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row modal_body">
                    <div className="col-md-12">
                        <p>Ingresa tu dirección de correo electrónico. Te enviaremos un código de verificación para confirmar tu identidad.</p>
                        <Form className="user">
                            
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                <Form.Group>
                                    <Form.Control 
                                        type="text" 
                                        value={formik.values.email} 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                        className="form-control form-control-user" 
                                        id="email"  
                                        name="email">

                                        </Form.Control>
                                </Form.Group>
                                </div>
                            </div>
                            <div className="alertmessage">{validateResponse}</div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <button type="button" className="btn btn-modal" onClick={forward}>Solicitar Cambio de Contraseña</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Modal>
        {openModal ? <ChangePasswordVerificationCode email={formik.values.email} letmodal={true} />: null}
    </>
    );
};