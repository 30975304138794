import Layout from "template";
import React, { useState, useEffect } from "react";
import { acceptComission, getComissionManage } from "services/comissionService";
import { Button } from "react-bootstrap";
import Modal from 'react-modal';

const customStylesModalRetiro = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      height: '300px',
      transform: 'translate(-50%, -50%)',
    },
};

export const CommissionManage: React.FC = () => {

    const [rData, setRData] = useState([]);
    const [modalRetiro, setModalRetiro] = useState(false);
    const [tituloModal, setTituloModal] = useState(null);
    const [mensajeModal, setMensajeModal] = useState(null);
    const [dataModal, setDataModal] = useState({ name: null, total_value: null });
    const [esAceptada, setEsAceptada] = useState(false);

    useEffect(() => {
        getComissionManage().then((response) => {
            setRData(response.data.records)
        })
    }, [])

    const convertirEstado = (estado) => {
        return estado === 0 ? 'RECHAZADO' : estado === 1 ? 'PENDIENTE' : 'APROBADO'
    }

    const convertirFecha = (fecha) => {
        const date = new Date(fecha);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
        return formattedDate
    }

    const aceptarComision = (usuarioComision) => {
        setEsAceptada(true)
        setDataModal(usuarioComision)
        setModalRetiro(true)
        setTituloModal('Aceptar comisión')
        setMensajeModal('A continuación aceptaras la comisión, luego de aceptado no podras cancelarla.')
    }

    const rechazarComision = (usuarioComision) => {
        setEsAceptada(false)
        setDataModal(usuarioComision)
        setModalRetiro(true)
        setTituloModal('Rechazar comisión')
        setMensajeModal('A continuación rechazaras la comisión, luego de rechazada no podras cancelarla.')
    }

    const aceptarModal = (dataComision) => {
        const data = { comission_id: dataComision.comission_id,
            requester: dataComision.requester,
            created_at: dataComision.created_at,
            status: esAceptada ? 2 : 0,
            total_value: dataComision.total_value }
        console.log(data)
        acceptComission(data, dataComision.id).then((_) => {
            setModalRetiro(false)
        })
    } 

    return (
        <Layout title="Comisiones"  icon="icon_MisNftColor_icon w-30" backlink={"#"} >
            <div className="container-fluid pt-body">
                <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                    <h2 className="font-w600 mr-auto "> 
                        <span className="mm-active">
                            <div className="personalizedicon"> <div className="icon_MisNftColor_icon w-30"></div> </div> 
                        </span> 
                            {'Administrar Comisiones'}
                    </h2>
                </div>
            </div>
            <div className="card-body pb-0" style={{ maxHeight: "520px", overflow: "auto" }}>
				<table className="table table-striped">
					<thead>
						<tr>
							<th className="center-th-td">Nombre</th>
							<th className="center-th-td">Valor total</th>
                            <th className="center-th-td">Estado</th>
							<th className="center-th-td">Fecha ingreso</th>
                            <th className="center-th-td">Acciones</th>
						</tr>
					</thead>
					<tbody>
                        {
                            rData?.map((event) => (
                            <tr>
                                <td className="center-th-td text-white">{event.name}</td>
                                <td className="center-th-td text-white">$ {event.total_value}</td>
                                <td className="center-th-td text-white">{convertirEstado(event.status)}</td>
                                <td className="center-th-td text-white">{convertirFecha(event.created_at)}</td>
                                <td className="center-th-td text-white">
                                    <Button disabled={event.status === 2 || event.status === 0} className="justify-content-end btn text-white m-r-10 btn-primary btn-info"
                                    onClick={() => aceptarComision(event)}>
                                        Aceptar
                                    </Button>
                                    <Button disabled={event.status === 2 || event.status === 0}
                                        className="justify-content-end btn text-white m-r-10 btn-danger"
                                        onClick={() => rechazarComision(event)}>
                                            Rechazar
                                    </Button>
                                </td>
                            </tr>
                            ))
                        }
					</tbody>
				</table>
			</div>
            <div className="container-fluid pt-body">
                <Modal
                    isOpen={modalRetiro}
                    style={customStylesModalRetiro}
                    ariaHideApp={false}
                    contentLabel="Example Modal">
                    <div className="modal_Box">
                        <div className="row modal_header m-l-5 m-r-5">
                            <div className="col-sm-11 p-l-0">
                                <h2>{tituloModal}</h2>
                            </div>
                            <div className="col-sm-1 p-r-0">
                                <div className="pull-right p-5px default-select bg-white b-none">
                                    <div className="personalizedicon mr-0 pt-0" onClick={() => setModalRetiro(false)}>
                                        <div className="icon_Close_icon w-15"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row modal_body">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <p>
                                            {mensajeModal}
                                        </p>
                                        <table className="table text-black"> 
                                            {
                                                <tr>
                                                    <td>{dataModal.name}</td>
                                                    <td>$ {dataModal.total_value}</td>
                                                </tr>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-container">
                            <button onClick={() => setModalRetiro(false)} type="button" className="col-xs-6 btn btn-rent-white">Cancelar</button>
                            <button onClick={() => aceptarModal(dataModal)} type="button" className="col-xs-6 btn btn-rent-white">Aceptar</button>
                    </div>
                </Modal>
            </div>
        </Layout >
      );
}
