import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useAppDispatch } from "redux/store";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { validateCode, forwardCode } from "services/usersService";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import { useFormik } from 'formik';
import * as yup from 'yup';
import {UpdatePaddwordForm} from './updatePasswordFormmodal';

export const ChangePasswordVerificationCode: React.FC<{email: string, letmodal: boolean}> = ({email, letmodal}) => {

    const navigate = useNavigate();    
    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        // alert(letmodal);
        setIsOpen(letmodal);
    }, [letmodal])
    
    

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          height: '340px',
          transform: 'translate(-50%, -50%)',
        },
    };
   
    const forward = async () => {
        try{
            const response = await forwardCode(email);
            if (response && (response.status === 200 || response.status === 201)) {
                setvalidateResponse("messange sent");
            } else {
                setvalidateResponse("messange un sent");
            }
        }catch(error){
            console.log("error", error);
        }
    }
    const [openModal, setopenModal] = useState(false);
    const formik = useFormik({
        initialValues: {
            boxa: '',
            boxb: '',
            boxc:'',
            boxd:''
        },

        onSubmit: async (values) => {
            const code = formik.values.boxa+''+formik.values.boxb+''+formik.values.boxc+''+formik.values.boxd;
            const response = await validateCode(email, code);
            console.log(response.data);
            if (response && (response.status === 200 || response.status === 201)) {
                setopenModal(true);
                setIsOpen(false);
            } else {
                setvalidateResponse(response.data.message);
            }
        },
    });

    const [activeField, setActiveField] = useState('username');
    const goToa = () => { setActiveField('boxa'); }
    const goTob = () => { setActiveField('boxb'); }
    const goToc = () => { setActiveField('boxc'); }
    const goTod = () => { setActiveField('boxd'); }

    useEffect(() => {
        const el = document.getElementById(activeField);
        // el.value("");
        el.focus();
    }, [activeField])

    const [validateResponse, setvalidateResponse] = useState('');
    Modal.setAppElement('#root');

    useEffect(() => {
        document.body.classList.toggle('LoginBp', true);
    }, [])


    return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="modal_Box">
                <div className="row modal_header m-l-5 m-r-5">
                    <div className="col-sm-11 p-l-0">
                        <h2>Confirmación de correo</h2>
                    </div>
                    <div className="col-sm-1 p-r-0">
                        <div className="pull-right p-5px default-select bg-white b-none">
                            <div className="personalizedicon mr-0 pt-0" onClick={closeModal}>
                                <div className="icon_Close_icon w-15"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row modal_body">
                    <div className="col-md-12">
                        <p>Te hemos enviado un código a tu correo, por favor ingrésalo para confirmar tu cuenta.</p>
                        <Form className="user"  onSubmit={formik.handleSubmit}>
                            <Form.Group>
                                <Form.Control type="hidden" value={email} className="form-control form-control-user" id="email"  name="email"></Form.Control>
                            </Form.Group>
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-2 col-sm-3">
                                    <Form.Group>
                                        <Form.Control type="text" className="form-control form-control-user radius-7" id="boxa" 
                                            value={formik.values.boxa}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} 
                                            onKeyUp={goTob}
                                            maxLength={1} 
                                            >
                                        </Form.Control>
                                        {formik.errors.boxa && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.boxa}
                                            </Form.Control.Feedback>
                                        )}                                    
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 col-sm-3">
                                    <Form.Group>
                                        <Form.Control type="text" className="form-control form-control-user radius-7" id="boxb" 
                                            value={formik.values.boxb}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}  
                                            onKeyUp={goToc}
                                            maxLength={1} 
                                            >
                                        </Form.Control>
                                        {formik.errors.boxb && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.boxb}
                                            </Form.Control.Feedback>
                                        )}                                    
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 col-sm-3">
                                    <Form.Group>
                                        <Form.Control type="text" className="form-control form-control-user radius-7" id="boxc" 
                                            value={formik.values.boxc}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} 
                                            maxLength={1} 
                                            onKeyUp={goTod}
                                            >
                                        </Form.Control>
                                        {formik.errors.boxc && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.boxc}
                                            </Form.Control.Feedback>
                                        )}                                    
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 col-sm-3">
                                    <Form.Group>
                                        <Form.Control type="text" className="form-control form-control-user radius-7" id="boxd" 
                                            value={formik.values.boxd}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}  
                                            maxLength={1} 
                                            onKeyUp={goToa}
                                            >
                                        </Form.Control>
                                        {formik.errors.boxd && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.boxd}
                                            </Form.Control.Feedback>
                                        )}                                    
                                    </Form.Group>
                                </div>


                                
                            </div>
                            <div className="alertmessage">{validateResponse}</div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <button type="button" className="btn btn-modal" onClick={forward}>Reenviar Código</button>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <button type="submit" className="btn btn-modal">Aceptar</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Modal>
        {openModal ? <UpdatePaddwordForm email={email} letmodal={true} />: null}
    </>
    );
};