import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setLocationsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addLocations, updateLocations } from "services/locationsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const LocationsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',franchise_id:'',name:'',description:'',address:'',city:'',country:'',latitude:'',longitude:'',property_type:'',units:'',mts:'',estimated_rent:'',rental_increment:'',image:'',created_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateLocations(values.id,values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setLocationsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setLocationsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addLocations(values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setLocationsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setLocationsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           franchise_id: yup.string(),
name: yup.string(),
description: yup.string(),
address: yup.string(),
city: yup.string(),
country: yup.string(),
latitude: yup.string(),
longitude: yup.string(),
property_type: yup.string(),
units: yup.string(),
mts: yup.string(),
estimated_rent: yup.string(),
rental_increment: yup.string(),
image: yup.string(),
created_at: yup.date(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Locations
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Locations</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">franchise_id</label>
<Form.Control type="text" name="franchise_id" className="form-control" value={formik.values.franchise_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.franchise_id && !!formik.errors.franchise_id}
isValid ={!!formik.touched.franchise_id && !formik.errors.franchise_id}
></Form.Control>
{
    formik.errors.franchise_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.franchise_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">name</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">description</label>
<Form.Control type="text" name="description" className="form-control" value={formik.values.description}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.description && !!formik.errors.description}
isValid ={!!formik.touched.description && !formik.errors.description}
></Form.Control>
{
    formik.errors.description && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.description}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">address</label>
<Form.Control type="text" name="address" className="form-control" value={formik.values.address}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.address && !!formik.errors.address}
isValid ={!!formik.touched.address && !formik.errors.address}
></Form.Control>
{
    formik.errors.address && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.address}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">city</label>
<Form.Control type="text" name="city" className="form-control" value={formik.values.city}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.city && !!formik.errors.city}
isValid ={!!formik.touched.city && !formik.errors.city}
></Form.Control>
{
    formik.errors.city && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.city}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">country</label>
<Form.Control type="text" name="country" className="form-control" value={formik.values.country}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.country && !!formik.errors.country}
isValid ={!!formik.touched.country && !formik.errors.country}
></Form.Control>
{
    formik.errors.country && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.country}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">latitude</label>
<Form.Control type="text" name="latitude" className="form-control" value={formik.values.latitude}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.latitude && !!formik.errors.latitude}
isValid ={!!formik.touched.latitude && !formik.errors.latitude}
></Form.Control>
{
    formik.errors.latitude && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.latitude}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">longitude</label>
<Form.Control type="text" name="longitude" className="form-control" value={formik.values.longitude}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.longitude && !!formik.errors.longitude}
isValid ={!!formik.touched.longitude && !formik.errors.longitude}
></Form.Control>
{
    formik.errors.longitude && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.longitude}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">property_type</label>
<Form.Control type="text" name="property_type" className="form-control" value={formik.values.property_type}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.property_type && !!formik.errors.property_type}
isValid ={!!formik.touched.property_type && !formik.errors.property_type}
></Form.Control>
{
    formik.errors.property_type && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.property_type}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">units</label>
<Form.Control type="text" name="units" className="form-control" value={formik.values.units}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.units && !!formik.errors.units}
isValid ={!!formik.touched.units && !formik.errors.units}
></Form.Control>
{
    formik.errors.units && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.units}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">mts</label>
<Form.Control type="text" name="mts" className="form-control" value={formik.values.mts}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.mts && !!formik.errors.mts}
isValid ={!!formik.touched.mts && !formik.errors.mts}
></Form.Control>
{
    formik.errors.mts && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.mts}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">estimated_rent</label>
<Form.Control type="text" name="estimated_rent" className="form-control" value={formik.values.estimated_rent}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.estimated_rent && !!formik.errors.estimated_rent}
isValid ={!!formik.touched.estimated_rent && !formik.errors.estimated_rent}
></Form.Control>
{
    formik.errors.estimated_rent && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.estimated_rent}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">rental_increment</label>
<Form.Control type="text" name="rental_increment" className="form-control" value={formik.values.rental_increment}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.rental_increment && !!formik.errors.rental_increment}
isValid ={!!formik.touched.rental_increment && !formik.errors.rental_increment}
></Form.Control>
{
    formik.errors.rental_increment && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.rental_increment}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">image</label>
<Form.Control type="text" name="image" className="form-control" value={formik.values.image}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.image && !!formik.errors.image}
isValid ={!!formik.touched.image && !formik.errors.image}
></Form.Control>
{
    formik.errors.image && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.image}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="date" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

