import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useAppDispatch } from "redux/store";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { passwordUpdate } from "services/usersService";
import { setError } from "redux/slices/auth";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import { useFormik } from 'formik';
import * as yup from 'yup';

export const FinishPasswordUpdate: React.FC<{letmodal: boolean}> = ({letmodal}) => {

    const navigate = useNavigate();    
    const dispatch = useAppDispatch();

    useEffect(() => {
        // alert(letmodal);
        setIsOpen(letmodal);
    }, [letmodal])
    
    

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          height: '200px',
          transform: 'translate(-50%, -50%)',
        },
    };
   
    const [modalIsOpen, setIsOpen] = React.useState(false);
    
    const [validateResponse, setvalidateResponse] = useState('');
    Modal.setAppElement('#root');

    useEffect(() => {
        document.body.classList.toggle('LoginBp', true);
    }, [])

    const [openModal, setopenModal] = useState(false);

    return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="modal_Box">
                <div className="row modal_header m-l-5 m-r-5">
                    <div className="col-sm-11 p-l-0">
                        <h2>Actualización de Clave</h2>
                    </div>
                    <div className="col-sm-1 p-r-0">
                        <div className="pull-right p-5px default-select bg-white b-none">
                            <div className="personalizedicon mr-0 pt-0" onClick={closeModal}>
                                <div className="icon_Close_icon w-15"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row modal_body">
                    <div className="col-md-12">
                        <h3 className="text-center">Su clave fue actualizada.</h3>
                    </div>
                </div>
            </div>
        </Modal>
        
    </>
    );
};