import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useAppDispatch } from "redux/store";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { passwordUpdate } from "services/usersService";
import { setError } from "redux/slices/auth";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import { useFormik } from 'formik';
import * as yup from 'yup';
import {FinishPasswordUpdate} from './finishPasswordUpdate';

export const UpdatePaddwordForm: React.FC<{email: string, letmodal: boolean}> = ({email, letmodal}) => {

    const navigate = useNavigate();    
    const dispatch = useAppDispatch();

    useEffect(() => {
        // alert(letmodal);
        setIsOpen(true);
        setopenConfirmModal(false);
    }, [letmodal])
    
    

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          height: '540px',
          transform: 'translate(-50%, -50%)',
        },
    };
   
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            password: '',
            repeatPassword: ''
        },

        onSubmit: async (values) => {
            const response = await passwordUpdate({'email': email, 'password': values.password});
            if (response) {
                setopenConfirmModal(true)
                setIsOpen(false);
                //navigate('/franchises', { replace: true });

            } else {
                dispatch(setError("No se pudo actualizar la clave"))
            }
        },
        validationSchema: yup.object({
            password: yup.string().trim().required('Password is required'),
            repeatPassword: yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: yup.string().oneOf(
                  [yup.ref("password")],
                  "Both password need to be the same"
                )
              })
        }),
    });
    const [validateResponse, setvalidateResponse] = useState('');
    Modal.setAppElement('#root');

    useEffect(() => {
        document.body.classList.toggle('LoginBp', true);
    }, [])

    const [confirmopenModal, setopenConfirmModal] = useState(false);

    return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="modal_Box">
                <div className="row modal_header m-l-5 m-r-5">
                    <div className="col-sm-11 p-l-0">
                        <h2>Confirmación de correo</h2>
                    </div>
                    <div className="col-sm-1 p-r-0">
                        <div className="pull-right p-5px default-select bg-white b-none">
                            <div className="personalizedicon mr-0 pt-0" onClick={closeModal}>
                                <div className="icon_Close_icon w-15"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row modal_body">
                    <div className="col-md-12">
                        <p>Excelente! tu código fue verificado correctamente, por favor ingrese su nueva contraseña.</p>
                        <Form className="user"  onSubmit={formik.handleSubmit}>
                            <div className="row">
                               
                                <div className="col-md-12 col-sm-12 mb-3 mb-sm-0">
                                    <Form.Group>
                                        <Form.Control 
                                            type="hidden" 
                                            value={email} 
                                            className="form-control form-control-user" 
                                            id="email"  
                                            name="email">

                                            </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <label className="text-gray-900 p-l-20 text-left">Contraseña </label>
                                        <div className="personalizedicon hidden-xs">
                                            <div className="icon_KeyBlack_icon w-30"></div>
                                        </div>
                                        <Form.Control type="password" className="form-control form-control-user" id="password"
                                            value={formik.values.password}
                                            placeholder="Contraseña"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!formik.touched.password && !!formik.errors.password}
                                            isValid={!!formik.touched.password && !formik.errors.password}
                                        ></Form.Control>
                                        {formik.errors.password && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.password}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </div>
                                <div className="col-md-12 col-sm-12 mb-3 mb-sm-0">
                                <Form.Group>
                                    <label className="text-gray-900 p-l-20 text-left">Confirmar Contraseña </label>
                                    <div className="personalizedicon hidden-xs">
                                        <div className="icon_ConfimationKeyBlack_icon w-30"></div>
                                    </div>
                                    <Form.Control type="password" className="form-control form-control-user" id="repeatPassword"
                                        value={formik.values.repeatPassword}
                                        placeholder="Repeat Password"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={!!formik.touched.repeatPassword && !!formik.errors.repeatPassword}
                                        isValid={!!formik.touched.repeatPassword && !formik.errors.repeatPassword}
                                    ></Form.Control>
                                    {formik.errors.repeatPassword && (
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.repeatPassword}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                </div>

                            </div>
                            <div className="alertmessage">{validateResponse}</div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <button type="submit" className="btn btn-modal">Cambiar Contraseña</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Modal>
        {confirmopenModal ? <FinishPasswordUpdate letmodal={true} />: null}
        
    </>
    );
};