import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useAppDispatch } from "redux/store";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { validateCode, forwardCode } from "services/usersService";
import { getOneFranchises, getCurrency } from "services/franchisesService";
import { addNfts, updateNfts } from "services/nftsService";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import { useFormik } from 'formik';
import { ethers } from "ethers";
import logo from '../../assets/images/rent/RentLogoColor02_icon.svg';
import * as yup from 'yup';
var md5 = require('md5');


export const WompiModal: React.FC<{ letmodal: boolean, setModal: Function, amount: number, franchiseId: string }> = ({ letmodal, amount, franchiseId, setModal }) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [franchise, setFranchise] = useState({ 'id': '', 'name': '', 'description': '', 'avatar': '', 'city': '', 'country': '', 'pricing_list': '', 'discounts': '', 'min_invest': '', 'expexted_profit': '', 'qualification': '', 'google_map': '', 'rents': '', 'nft_description': '', 'valor_ticket': '' });
    const [payReference, setPayReference] = useState(0);


    const closeModal = () => {
        setModal(false);
    }

    useEffect(() => {

        getOneFranchises(franchiseId).then((response) => {
            setFranchise(response.data);
        })

    }, [])

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            height: '650px',
            transform: 'translate(-50%, -50%)',
        },
        Select: {
            paddingTop: '2px !important',
            paddingBottom: '0px !important'
        }
    };

    const style = {
        logo: {
            width: '50%',
        }
    }

    Modal.setAppElement('#root');

    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : undefined;

    const [submitRequested, setSubmitRequested] = useState(false);


    const handleSubmit = e => {
        HandleShop()
        e.preventDefault()
    }

    const HandleShop = async () => {


        const idTicket = franchise.id;
        // console.log(result);
        let time = new Date();

        const tokenId = md5(franchise.id + userProfile.id + time);

        const values = {
            file: franchise.avatar,
            contact: userProfile.name,
            description: franchise.nft_description,
            author: "RENT",
            owner: userProfile.id,
            status: '0',
            views: 0,
            favorites: 0,
            price: amount,
            sale_date: time,
            contact_address: '',
            token_id: tokenId,
            token_standard: "",
            blockchain: '',
            metadata: JSON.stringify(franchise),
            franchise_id: franchise.id,
            created_at: time,
        }

        console.log(values);
        setPayReference(tokenId);
        const response = await addNfts(values);
        if (response && (response.status === 200 || response.status === 201)) {
            console.log(response);
            document.getElementById("submitform").click();

        } else {
            console.log("Some error occured!");
        }
    }

    return (
        <>
            <Modal
                isOpen={letmodal}
                onRequestClose={setModal}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="modal_Box">
                    <div className="row modal_header m-l-5 m-r-5">
                        <div className="col-sm-11 p-l-0">
                            <h2>Completa tu compra</h2>
                        </div>
                        <div className="col-sm-1 p-r-0">
                            <div className="pull-right p-5px default-select bg-white b-none">
                                <div className="personalizedicon mr-0 pt-0" onClick={closeModal}>
                                    <div className="icon_Close_icon w-15"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row modal_body">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p className="text-left">
                                        Completa los datos antes de finalizar tu compra
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <form action="https://checkout.wompi.co/p/" method="GET">
                                        <div className="row m-b-20">
                                            <div className="col-md-5">
                                                <b>Franquicia:</b>
                                            </div>
                                            <div className="col-md-7">
                                                {franchise.nft_description}
                                            </div>
                                            <div className="col-md-5">
                                                <b>Valor a Pagar en COP:</b>
                                            </div>
                                            <div className="col-md-7">
                                                {amount.toLocaleString('es-MX')}
                                            </div>
                                        </div>
                                        <div className="row m-b-20 mb-50">
                                            <div className="col-md-12">
                                                <label htmlFor="" className="text-left m-b-0">
                                                    Nombre Completo
                                                </label>
                                                <input type="text" className="form-control" name="customer-data:full-name" value={userProfile.name + ' ' + userProfile.last_name} />
                                            </div>
                                        </div>
                                        <div className="row m-b-20 mb-50">
                                            <div className="col-md-6">
                                                <label htmlFor="" className="text-left m-b-0">
                                                    E-mail
                                                </label>
                                                <input type="text" className="form-control" name="customer-data:email" value={userProfile.email} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="" className="text-left m-b-0">
                                                    Número de Teléfono
                                                </label>
                                                <input type="text" className="form-control" name="customer-data:phone-number" />
                                            </div>

                                        </div>
                                        <div className="row m-b-20 mb-50">
                                            <div className="col-md-6">
                                                <label htmlFor="" className="text-left m-b-0">
                                                    Número de Identificación
                                                </label>
                                                <input type="text" className="form-control" name="customer-data:legal-id" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="" className="text-left m-b-0">
                                                    Tipo de Identificación
                                                </label>
                                                <select name="customer-data:legal-id-type" style={customStyles.Select} className="form-control" id="customer-data:legal-id-type">
                                                    <option value="PP">PP</option>
                                                    <option value="CC">CC</option>
                                                    <option value="NIT">NIT</option>
                                                    <option value="CE">CE</option>
                                                    <option value="TI">TI</option>
                                                    <option value="DNI">DNI</option>
                                                    <option value="RG">RG</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row m-b-20 mb-50">
                                            <div className="col-md-6">
                                                <label htmlFor="" className="text-left m-b-0">
                                                    Dirección
                                                </label>
                                                <input type="text" className="form-control" name="shipping-address:address-line-1" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="" className="text-left m-b-0">
                                                    Ciudad
                                                </label>
                                                <input type="text" className="form-control" name="shipping-address:city" />
                                                <input type="hidden" className="form-control" name="reference" value={payReference} />
                                            </div>
                                        </div>
                                        <input type="hidden" className="form-control" name="amount-in-cents" value={amount + '00'} />
                                        <input type="hidden" name="public-key" value={process.env.REACT_APP_WOMPI_PUBLIC_KEY} />
                                        <input type="hidden" name="redirect-url" value={process.env.REACT_APP_WOMPI_BACKLINK} />

                                        <input type="hidden" name="currency" value="COP" />
                                        <input type="hidden" name="shipping-address:country" value="CO" />
                                        <input type="hidden" name="shipping-address:region" value="ATL" />
                                        <input type="hidden" name="shipping-address:phone-number" value="300000000" />

                                        <button type="button" onClick={HandleShop} className="btn btn-user btn-block btn-rent-white mt-50 mb-50" style={{ marginBottom: "0px" }}>Pagar con Wompi</button>
                                        <button type="submit" id="submitform" className="btn dn btn-user btn-block btn-rent-white mt-50 mb-50">sendForm</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};