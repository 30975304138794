import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import ContactSlider from "../zenix/Home/ContactSlider";
import IndexTab from "../zenix/Home/IndexTab";
import Layout from "template";
import { MenuItems } from "template/MenuItems";
//Images
import btcIcon from  '../../assets/images/rent/BitcoinColor_icon.svg';


export const Tradingview: React.FC = () => {

		  return (
    <Layout title="Titulo"  icon="icon_DashboardColor_icon w-30" backlink={"#"} >
			<Fragment>
			<div className="container-fluid pt-body">
				<div className="row">
					<div className="col-xl-12 col-sm-12 m-t35">

						<div className="card card-coin">
							<div className="card-body text-center p-t-40 p-b-40">
								<h5 className="text-white text-left">TradingView</h5>
								<div dangerouslySetInnerHTML={{ __html: "<iframe src='https://rent.hitracker.org/' style='height:50vh; width:100%; overflow-y:hidden' />"}} />
							</div>
						</div>
					</div>
				</div>
			</div>
			</Fragment>
      </Layout>
		)
	
}