import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setUsersMessage } from "redux/actions";
import { useAppDispatch } from "redux/store";
import { addUsers, updateUsers } from "services/usersService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const UsersForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',name:'',email:'',password:'',refresh_token:'',createdAt:'',updatedAt:'',rol:'',wallet_id:'',birthday:'',last_name:'',avatar:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateUsers(values.id,values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setUsersMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUsersMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addUsers(values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setUsersMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUsersMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           name: yup.string(),
email: yup.string(),
password: yup.string(),
refresh_token: yup.string(),
createdAt: yup.date().required('createdAt is required'),
updatedAt: yup.date().required('updatedAt is required'),
rol: yup.number().required('rol is required'),
wallet_id: yup.string(),
birthday: yup.string(),
last_name: yup.string().required('last_name is required'),
avatar: yup.string(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Users
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Users</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">name</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">password</label>
<Form.Control type="text" name="password" className="form-control" value={formik.values.password}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.password && !!formik.errors.password}
isValid ={!!formik.touched.password && !formik.errors.password}
></Form.Control>
{
    formik.errors.password && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.password}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">refresh_token</label>
<Form.Control type="text" name="refresh_token" className="form-control" value={formik.values.refresh_token}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.refresh_token && !!formik.errors.refresh_token}
isValid ={!!formik.touched.refresh_token && !formik.errors.refresh_token}
></Form.Control>
{
    formik.errors.refresh_token && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.refresh_token}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">createdAt</label>
<Form.Control type="date" name="createdAt" className="form-control" value={formik.values.createdAt}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.createdAt && !!formik.errors.createdAt}
isValid ={!!formik.touched.createdAt && !formik.errors.createdAt}
></Form.Control>
{
    formik.errors.createdAt && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.createdAt}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updatedAt</label>
<Form.Control type="date" name="updatedAt" className="form-control" value={formik.values.updatedAt}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updatedAt && !!formik.errors.updatedAt}
isValid ={!!formik.touched.updatedAt && !formik.errors.updatedAt}
></Form.Control>
{
    formik.errors.updatedAt && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updatedAt}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">rol</label>
<Form.Control type="number" name="rol" className="form-control" value={formik.values.rol}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.rol && !!formik.errors.rol}
isValid ={!!formik.touched.rol && !formik.errors.rol}
></Form.Control>
{
    formik.errors.rol && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.rol}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">wallet_id</label>
<Form.Control type="text" name="wallet_id" className="form-control" value={formik.values.wallet_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.wallet_id && !!formik.errors.wallet_id}
isValid ={!!formik.touched.wallet_id && !formik.errors.wallet_id}
></Form.Control>
{
    formik.errors.wallet_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.wallet_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">birthday</label>
<Form.Control type="text" name="birthday" className="form-control" value={formik.values.birthday}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.birthday && !!formik.errors.birthday}
isValid ={!!formik.touched.birthday && !formik.errors.birthday}
></Form.Control>
{
    formik.errors.birthday && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.birthday}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">last_name</label>
<Form.Control type="text" name="last_name" className="form-control" value={formik.values.last_name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.last_name && !!formik.errors.last_name}
isValid ={!!formik.touched.last_name && !formik.errors.last_name}
></Form.Control>
{
    formik.errors.last_name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.last_name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">avatar</label>
<Form.Control type="text" name="avatar" className="form-control" value={formik.values.avatar}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.avatar && !!formik.errors.avatar}
isValid ={!!formik.touched.avatar && !formik.errors.avatar}
></Form.Control>
{
    formik.errors.avatar && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.avatar}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

