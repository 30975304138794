import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import { getOneFranchises, } from "services/franchisesService";
import { addNfts } from "services/nftsService";
var md5 = require('md5');


export const TransferModal: React.FC<{ letmodal: boolean, setModal: Function, amount: number, franchiseId: string }> = ({ letmodal, amount, franchiseId, setModal }) => {

    const [franchise, setFranchise] = useState({ 'id': '', 'name': '', 'description': '', 'avatar': '', 'city': '', 'country': '', 'pricing_list': '', 'discounts': '', 'min_invest': '', 'expexted_profit': '', 'qualification': '', 'google_map': '', 'rents': '', 'nft_description': '', 'valor_ticket': '' });

    useEffect(() => {

        getOneFranchises(franchiseId).then((response) => {
            setFranchise(response.data);
        })

    }, [])

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            height: '650px',
            transform: 'translate(-50%, -50%)',
        },
        Select: {
            paddingTop: '2px !important',
            paddingBottom: '0px !important'
        }
    };

    const style = {
        logo: {
            width: '50%',
        }
    }

    Modal.setAppElement('#root');

    const closeModal = () => {
        setModal(false);
    }

    return (
        <>
            <Modal
                isOpen={letmodal}
                onRequestClose={setModal}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="modal_Box">
                    <div className="row modal_header m-l-5 m-r-5">
                        <div className="col-sm-11 p-l-0">
                            <h2>Completa tu compra</h2>
                        </div>
                        <div className="col-sm-1 p-r-0">
                            <div className="pull-right p-5px default-select bg-white b-none">
                                <div className="personalizedicon mr-0 pt-0" onClick={closeModal}>
                                    <div className="icon_Close_icon w-15"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row modal_body">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p className="text-left">
                                        Completa los datos antes de finalizar tu compra
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <form action="https://checkout.wompi.co/p/" method="GET">
                                        <div className="row m-b-20">
                                            <div className="col-md-4">
                                                <b>Franquicia:</b>
                                            </div>
                                            <div className="col-md-8">
                                                {franchise.nft_description}
                                            </div>
                                            <div className="col-md-4">
                                                <b>Valor a Pagar en COP:</b>
                                            </div>
                                            <div className="col-md-8">
                                                {amount.toLocaleString('es-MX')}
                                            </div>
                                        </div>
                                        <div className="row m-b-20 m-t-20">
                                            <div className="col-md-12">
                                                <p>
                                                    Para pagos con transferencias o consignaciones:
                                                </p>
                                                <p>
                                                    Cuenta corriente bancolombia <b>554-000047-15</b> a nombre de <b>4 RIVERS INVESTMENT GROUP S.A.S.</b> NIT 901.610.323
                                                </p>
                                                <p>
                                                    Por favor envíanos el soporte de la transacción con tu firma al correo hello@nurent.net,
                                                </p>
                                                <p>
                                                    Si la transferencia fue realizada por bancolombia en 24 horas hábiles verás reflejada tu participación.
                                                    Si la transferencia fue realizada por otros bancos, en 48 horas hábiles verás reflejada tu participación.
                                                </p>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};