import React, { useEffect, useState } from "react";
import Modal from 'react-modal';

export const DataTreatmentModal: React.FC<{letmodal: boolean}> = ({letmodal}) => {
  
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // alert(letmodal);
        setIsOpen(letmodal);
    }, [letmodal])

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          height: '490px',
          transform: 'translate(-50%, -50%)',
        },
    };
 
    Modal.setAppElement('#root');

    return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Data Treatment Modal">
            <div className="modal_Box">
                <div className="row modal_header m-l-5 m-r-5">
                    <div className="col-sm-11 p-l-0">
                        <h2>Autorización para el Tratamiento de Datos Personales</h2>
                    </div>
                    <div className="col-sm-1 p-r-0">
                        <div className="pull-right p-5px default-select bg-white b-none">
                            <div className="personalizedicon mr-0 pt-0" onClick={closeModal}>
                                <div className="icon_Close_icon w-15"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row modal_body">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 text-justify">
                                <p>
                                    4 RIVERS en cumplimiento de la Ley 1581 de 2012 y demás normas concordantes, es responsable del tratamiento de sus datos personales, por ende, le comunicamos que sus datos personales serán utilizados para los siguientes fines: 
                                </p>
                                <ul>
                                    <li style={{listStyle:'circle'}}>Adelantar los trámites y servicios relacionados con la suscripción de este formulario</li>
                                    <li style={{listStyle:'circle'}}>Enviarle información del modelo de negocio y sus actualizaciones.</li>
                                    <li style={{listStyle:'circle'}}>Verificar la idoneidad de su identificación, determinando la validez del documento a través de entidad aliada encargada de este proceso de validación</li>
                                    <li style={{listStyle:'circle'}}>En caso de ser necesario los datos personales serán transferidos a nuestros asociados internacionales, en tal caso realizaremos los mayores esfuerzos para efectuarlo en el marco de la regulación vigente.</li>
                                </ul>
                                <p>Como titular de información tiene derecho a conocer, actualizar y rectificar sus datos personales y, sólo en los casos en que sea procedente, a suprimirlos o revocar la autorización otorgada para su tratamiento.</p>
                                <p>Con el registro en este formulario y su confirmación usted nos autoriza expresamente el tratamiento de los datos personales que nos suministra conforme a las finalidades aquí descritas, además de conocer y aceptar plenamente la política de privacidad la cual puede ser consultada <a href="https://nurent.net" target="_blank">aquí</a></p>
                                <p>Si desea presentar una consulta, reclamo o petición de información relacionada con la protección de datos personales puede ingresar a la página web www.nurent.net o comunicarse a la línea (316) 6234210 o al correo hello@nurent.net.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
    );
};