import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { getRentability } from "services/nftsService";
import { setNft_FavoritesList, setNft_FavoritesMessage } from "redux/actions";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getNft_Favorites } from "services/nft_favoritesService";
import { Constant } from "template/Constant";
import { Tabla } from './tabla';


export const ApexBar3: React.FC = () => {
  const [totalFranchises, settotalFranchises] = useState(0);
  const [listReferrals, setTotalReferrals] = useState([]);
  const [rData, setRData] = useState('') ;
  // getRentability().then((response) => {
  //   const query = response.data.records;
  //   console.log("->",query);
  //   setRow(query);
  // });

  useEffect(() => {
      getRentability().then((response) => {
        response.data.records.map((e) => {
              const elm = e.total;
              const label = e.description;
              let val_percent = undefined;
              if (e.franchise_id == '1'){
                 val_percent = elm * 0.17; 
                 val_percent = val_percent.toLocaleString('es-MX');
                 
              }
              if (e.franchise_id == '7'){
                  let ca =  elm * 0.15;
                  let cb =  elm * 0.21;  
                  let text = ca.toLocaleString('es-MX')+'1 año, <br>'+cb.toLocaleString('es-MX')+' 2 años';
                  val_percent = text;
              }
              let str = '{"total":"'+elm.toLocaleString('es-MX')+'","name":"'+label+'", "percent":"'+val_percent+'"}';
              setTotalReferrals(listReferrals => [...listReferrals, str ]);
              // setTotalReferrals(listReferrals+str);
          })
        })

  },[]);
  
  useEffect(() => {
    setRData('['+listReferrals+']');
  },[listReferrals]);
  
  
    return (
      <div id="chart" className="apex-chart">
          <table className="table table-striped">
            <Tabla lista={rData != '' ? rData:'[]'} />
          </table>
      </div>
    );
  
}