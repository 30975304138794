import { APIService } from "services";

export const getItem_Activities = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllItem_Activities(pageNo,pageSize);
    }
    else{
        try {
            res = await searchItem_Activities(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addItem_Activities = (data) => {
return APIService.api().post(`/item_activities`,data)
}
export const updateItem_Activities = (id,data) => {
return APIService.api().patch(`/item_activities/${id}`,data)
}
export const getAllItem_Activities = (pageNo,pageSize) => {
return APIService.api().get(`/item_activities/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneItem_Activities = (id) => {
return APIService.api().get(`/item_activities/${id}`)
}
export const searchItem_Activities = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/item_activities/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteItem_Activities = (id) => {
return APIService.api().delete(`/item_activities/${id}`)
}
