import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import ContactSlider from "../zenix/Home/ContactSlider";
import IndexTab from "../zenix/Home/IndexTab";
import Layout from "template";
import { MenuItems } from "template/MenuItems";
//Images
import btcIcon from  '../../assets/images/rent/BitcoinColor_icon.svg';


export const TradingViewWidget: React.FC = () => {

    if (document.getElementById("chart")) {
    const script = document.createElement('script');

    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
    script.async = true;
    script.innerHTML = JSON.stringify(  {
        "symbols": [
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "Bitcoin"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "Ethereum"
          },
          {
            "description": "",
            "proName": "UNISWAP3ETH:RENTUSDC"
          },
          {
            "description": "",
            "proName": "FX_IDC:USDCOP"
          },
          {
            "description": "",
            "proName": "AMEX:SPY"
          }
        ],
        "showSymbolLogo": true,
        "colorTheme": "light",
        "isTransparent": true,
        "displayMode": "adaptive",
        "locale": "es"
      });
    
    document.getElementById("chart")!.appendChild(script);
}

        return (<div className="tradingview-widget-container" style={{ height:'50px', width: '75%',float: 'left'}}>
                    <div className="tradingview-widget-container__widget" id='chart'></div>
                </div>);

}