import React, { useState, useEffect, useContext, createContext } from "react";
import { Alert, Button, Card, Col, Form, InputGroup, Tab, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { setFranchises_DetailList, setFranchises_DetailMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getFranchises_Detail } from "services/franchises_detailService";
import Layout from "template";
import { Constant } from "template/Constant";
import { getOneFranchises, getCurrency } from "services/franchisesService";
import { Franchises_DetailForm } from "./form";
import { Franchises_DetailTable } from "./table";
import Parser from 'html-react-parser';
import franchises_detail from "redux/slices/franchises_detail";
import { useNavigate } from "react-router";
import { CartDispatchContext, addToCart } from "../../contexts/cart";
import { contractAbi, contractAdress, providerTmp } from "../../contexts/contractAbiSandBox";
import { ethers } from "ethers";
import { addNfts, updateNfts } from "services/nftsService";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { WompiModal } from "../modal/wompiModal";
import { TransferModal } from "../modal/transferModal";

const CurrentChart = loadable(() =>
    pMinDelay(import("../zenix/Chartjs/line1"), 1000)
);


export const Franchises_DetailWithNoWallet: React.FC = (props) => {


    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dp = useContext(CartDispatchContext);
    let uri = location.pathname;
    let parts = uri.split("/");
    const query = parts[2];
    // console.log(query);
    const rData = useSelector((state: RootState) => state.franchises_detail);
    const [row, setRow] = useState(undefined);
    const [action, setAction] = useState('');
    const [franchise, setFranchise] = useState({ 'id': '', 'name': '', 'description': '', 'avatar': '', 'city': '', 'country': '', 'pricing_list': '', 'discounts': '', 'min_invest': '', 'expexted_profit': '', 'qualification': '', 'google_map': '', 'rents': '', 'nft_description': '', 'valor_ticket': '', 'franchise_type': '', 'seguimiento_link': '', 'unit_pricing_list':'', 'status': '', 'total_rents': 0, 'rents_disponibles': 0});
    const [data, setData] = useState({ 'id': '', 'name': '', 'price': '', 'image': '' });
    const [isAdded, setIsAdded] = useState(false);
    const [currency, setCurrency] = useState(null);
    const [contractPriceCOP, setContractPriceCOP] = useState(1);
    const [nameContract, setnameContract] = useState({
        idFranchise: '-',
        amountUsd: '-',
        amountStake: '-',
        limitByFranchise: '-',
        limitByUser: '-',
        activate: '-',
        purchases: 0,
        available: 0,
        cop: 0
    });
    const [purchases, setPurchases] = useState(0);
    const [HandleCotizador, setHandleCotizador] = useState("0");
    const [contratos, setContratos] = useState(null);
    const [contractPrice, setContractPrice] = useState(1);
    const [contractCopPrice, setContractCopPrice] = useState(null);
    const [price, setPrice] = useState(null);
    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : undefined;
    const name = String(Object.values(userProfile)[1]);
    const backlink = '/franchises';
    const [value, setValue] = useState('checking value...');

    const zerosForContracts = 18;



    const viewLocations = (path) => {
        const newpath = '/locations/' + path;
        navigate(newpath, { replace: true });
    }

    const handleRowEdit = (rowData) => {
        setRow(rowData);
        dispatch(setFranchises_DetailMessage(''));
        setAction('edit');
    }

    const Stars = (num) => {

        // num = parseInt(num);
        var items = "";
        num = 1;
        for (var k = 0; k < num; k++) {
            items += '<div className="personalizedicon m-r-5"><div className="icon_PropiedadesColor_icon w-10"></div></div> ';
        }
        return items;
    }

    const getData = (page, pageSize, searchKey) => {

        let uri = location.pathname;
        let parts = uri.split("/");
        let query = parts[2];

        getFranchises_Detail(page, pageSize, query).then((response) => {
            if (response && response.records) {
                dispatch(setFranchises_DetailList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.totalCount, searchKey: searchKey }));
            } else {
                dispatch(setFranchises_DetailMessage("No Record Found"));
            }
        })
    }


    useEffect(() => {
        console.log('QUERY', query);
        console.log('uri', uri);
        getOneFranchises(query).then((response) => {
            setFranchise(response.data);
        })

        getCurrency().then((response) => {
            console.log("valor usd cop", response.usd.inverseRate);
            setCurrency(response.usd.inverseRate);
            setContractCopPrice(response.usd.inverseRate)
        })
        setnameContract({
            idFranchise: franchise.id,
            amountUsd: franchise.valor_ticket,
            amountStake: '280',
            limitByFranchise: '280000',
            limitByUser: "280",
            activate: "1",
            purchases: 1,
            available: 10,
            cop: 0
        });
        setContractPrice(parseInt(franchise.valor_ticket));

        document.getElementById("getdatacontract").click();

        let isMounted = true;
        setValue("done!"); // no more error
        let currentsales = "150";
        setPurchases(parseInt(currentsales));



        let limit = parseInt("280000");
        let availableTokens = franchise.rents_disponibles;
        setnameContract({
            idFranchise: franchise.id,
            amountUsd: franchise.pricing_list,
            amountStake: franchise.valor_ticket,
            limitByFranchise: franchise.min_invest,
            limitByUser: franchise.min_invest,
            activate: "1",
            purchases: purchases,
            available: availableTokens,
            cop: 0,
        });
        setContractPrice(parseInt(franchise.valor_ticket));


    }, [])

    function handleChange(event) {
        setHandleCotizador(event.target.value);
    }

    useEffect(() => {
        setContratos(HandleCotizador);
        const newprice = (parseInt(HandleCotizador) * parseInt(franchise.valor_ticket))
        setPrice(newprice);
        setContractPrice(newprice);

        const copPrice = (Math.round(currency) * newprice);
        setContractPriceCOP(copPrice);

    }, [HandleCotizador])


    const HandleShop = async () => {

        console.log("hola!");

        const idTicket = franchise.id;
        const newprice = "5000";

        console.log(query, idTicket, HandleCotizador, String(newprice));

        let time = new Date();
        const values = {
            file: franchise.avatar,
            contact: name,
            description: franchise.nft_description,
            author: "RENT",
            owner: userProfile.id,
            status: 1,
            views: 0,
            favorites: 0,
            price: price,
            sale_date: time,
            contact_address: "N/A",
            token_id: "N/A",
            token_standard: "",
            blockchain: "N/A",
            metadata: JSON.stringify("{it:'N/A'}"),
            franchise_id: franchise.id,
            created_at: time,
        }

        // 45000 000000
        // console.log(values)
        const response = await addNfts(values);
        if (response && (response.status === 200 || response.status === 201)) {
            const newpath = '/nfts/';
            navigate(newpath, { replace: true });
        } else {
            console.log("Some error occured!");
        }

    }
    const [buscadorstatus, setBuscadorstatus] = useState('-');
    // const handleValue = async () => {

    //     setnameContract({    
    //         idFranchise : franchise.id,
    //         amountUsd : '5000',
    //         amountStake : '5000',
    //         limitByFranchise : '280',
    //         limitByUser : '280',
    //         activate : '1',
    //         purchases: 10,
    //         available: 280,
    //         cop : 0,
    //     });
    // }

    const handleValue = async () => {

        setBuscadorstatus('Consultando Ticket...');
        setPurchases(parseInt('10'));

        let limit = parseInt("170000");
        let availableTokens = limit - purchases;
        setnameContract({
            idFranchise: franchise.id,
            amountUsd: franchise.pricing_list,
            amountStake: franchise.valor_ticket,
            limitByFranchise: '280000',
            limitByUser: franchise.min_invest,
            activate: "1",
            purchases: purchases,
            available: availableTokens,
            cop: 0,
        });
        setBuscadorstatus('Resultados cargados...');

    }
    const [openModal, setopenModal] = useState(false);
    const [openModalTransfer, setopenModalTransfer] = useState(false);
    const submitValidation = () => {
        setopenModal(true);
        console.log(openModal)
    }

    const availabletoPurchase = (franchise.rents_disponibles == 0)?'btn btn-rent-invert btn btn-link text-white m-t-10 dn':'btn btn-rent-invert btn btn-link text-white m-t-10';
    const paymessages = (franchise.rents_disponibles == 0)?'Esta franquicia está agotada':'Paga con:';
    return (
        <Layout title={franchise.name} icon="icon_DashboardColor_icon w-30" backlink={backlink}>
            <div className="container-fluid pt-body">
                <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                    <h2 className="font-w600 mr-auto ">
                        <span className="mm-active">
                            <div className="personalizedicon"> <div className="icon_DashboardColor_icon w-30"></div> </div>
                        </span>
                        {franchise.name}
                    </h2>
                </div>

                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className="card">
                            <div className="card-body p-30">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-5  col-md-5 col-xxl-4 ">
                                        {/* Tab panes */}
                                        <Tab.Container defaultActiveKey="first">

                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <div className="contenedor">
                                                        <figure>
                                                            <img src={franchise.avatar} className="img-fluid" style={{height: '300px' }} />
                                                        </figure>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                        
                                        <div className="comment-review star-rating d-flex m-t-10">
                                            <div className="fs-15">
                                                <a href={franchise.franchise_type} target="_blank" className="btn text-white m-r-10 btn-primary btn-rent-invert"> Descargar Statement</a>
                                            </div>
                                        </div>

                                    </div>
                                    {/*Tab slider End*/}

                                    <div className="col-xl-8 col-lg-7  col-md-7 col-xxl-8 col-sm-12">
                                        <div className="product-detail-content">
                                            {/*Product details*/}
                                            <div className="new-arrival-content pr">
                                                <div className="d-flex">
                                                    <h4 className="m-r-20">{franchise.name} {franchise.city}, {franchise.country}</h4>
                                                </div>
                                                <p className="text-rent item text-white text-justify">{Parser(franchise.description)}</p>


                                                <div className="contenedor">
                                                    <div className="shopping-cart mt-30 d-flex">
                                                        <Button variant="link" className="btn text-white m-r-10 btn-primary btn-rent-invert" onClick={() => viewLocations(franchise.id)}>Ver Propiedades</Button>

                                                        <a href={franchise.seguimiento_link} target="_blank" className="btn text-white m-r-10 btn-primary btn-rent-invert"> Seguimiento de las Propiedades</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="card">
                            <div className="card-body p-30">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="product-detail-content">
                                            <div className="new-arrival-content pr">
                                                <div className="d-flex">
                                                    <h4 className="m-r-20">Ubicación de las propiedades</h4>
                                                </div>
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: franchise.google_map }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="d-flex flex-column ">
                            {rData.message ?
                                <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
                                    <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setFranchises_DetailMessage(''))}>
                                        <span aria-hidden="true">&times;</span>
                                    </Button>
                                </Alert> : null}
                            {action ? <Franchises_DetailForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
                                <Franchises_DetailTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />}
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="d-flex flex-column">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body p-30">
                                            <table className="table text-white">
                                                <thead>
                                                    <tr>
                                                        <th>Descripción</th>
                                                        <th>UNIDADES</th>
                                                        <th>Nombres</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Propiedades Rentadas</td>
                                                        <td>7</td>
                                                        <td>19029 Plainview</td>
                                                    </tr>   
                                                    <tr>
                                                        <td>Propiedades Disponibles</td>
                                                        <td>4</td>
                                                        <td>19982 St aubin + 558 Holbrook</td>
                                                    </tr>
                                                    <tr>
                                                        <td>En Proceso</td>
                                                        <td>1</td>
                                                        <td>3259 E 123rd St, Cleveland, OH 44120, 13102 stoepel, 14100 E. State Fair, 20091 Barlow, 19170 Joann, 13718 E. State Fair, 15060 Hazelridge, 20017 Pelkey (48205)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Propiedades</td>
                                                        <td>12</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="d-flex flex-column">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-body p-30">
                                            <CurrentChart />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-body p-30">

                                            <div className="box-cotizador">
                                                <div className="header_cotizador">
                                                    <h5 className="text-center">Comprar</h5>
                                                </div>
                                                <div className="body_cotizador">
                                                    <div className="d-flex justify-content-center">
                                                        <p className="mb-0">
                                                            <div className="personalizedicon m-r-5"> <div className="icon_PrecioSugeridoColor_Icon w-15"></div> </div>Contratos
                                                        </p>
                                                    </div>
                                                    <div className="d-flex justify-content-center m-t-20">
                                                        <input type="number" id="contratosCant" onChange={handleChange} min="1" max={nameContract.available} className="form-control w-50  text-center style-1 default-select mr-3 p-0" />
                                                    </div>

                                                    <div className="d-flex justify-content-center">
                                                        <div className="m-l-40 personalizedicon m-t-30"> <div className="icon_RentLogoColor01_icon wx-50"></div> </div>
                                                        <div className="p-t-5 m-t-30">Totales: {franchise.rents}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="m-l-40 personalizedicon"> <div className="icon_RentLogoColor01_icon wx-50"></div> </div>
                                                        <div className="p-t-5">Disponibles: {nameContract.available}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-center m-t-30">
                                                        <p className="mb-0">Precio de Lista</p>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <p className="text-rent fs-18"> <div className="personalizedicon m-r-5"> <div className="icon_PrecioSugeridoColor_Icon w-15"></div> </div><span className="item bold"><b> ${franchise.unit_pricing_list}</b></span></p>
                                                    </div>
                                                    <div className="d-flex justify-content-center m-t-20 dn">
                                                        <input type="number" id="contratosCant" value={contratos} className="form-control  w-50 text-center style-1 bg-white text-gray" />
                                                    </div>
                                                    <div className="d-flex justify-content-center ">
                                                        <input type="hidden" id="idFranchise" value={nameContract.idFranchise} className="form-control  w-50 text-center style-1 default-select bg-white text-gray" />
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="p-l-30">
                                                                {/* <div className="personalizedicon m-r-5"> <div className="icon_InversionMinimaColor_Icon w-15"></div></div> */}
                                                                <p className="m-b-0 dn">Precio del Contrato</p>
                                                                <p className="p-t-0 dn text-rent fs-20">$ {contractPrice} RENT</p>
                                                                <p className="p-t-0 text-rent fs-20">$ {contractPrice.toLocaleString('es-MX')} USD</p>
                                                                {/* <p className="p-t-0 text-rent fs-20">$ {contractPriceCOP} COP</p> */}
                                                                {<p className="p-t-0 text-rent fs-20">$ {contractPriceCOP.toLocaleString('es-MX')} COP</p>}
                                                            </div>
                                                        </div>
                                                        <div className="container col-md-12 ml-3">
                                                            <h4>{paymessages}</h4>
                                                            <div className="row justify-content-around">  
                                                                <button type="button" className={availabletoPurchase} onClick={submitValidation}>Wompi</button>
                                                                <button type="button" className={availabletoPurchase} onClick={() => setopenModalTransfer(true)}>Transferencia</button>
                                                            </div>
                                                            <WompiModal letmodal={openModal} setModal={setopenModal} franchiseId={franchise.id} amount={contractPriceCOP} />
                                                            <TransferModal letmodal={openModalTransfer} setModal={setopenModalTransfer} franchiseId={franchise.id} amount={contractPriceCOP} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="footer_cotizador"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row dn">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="d-flex flex-column">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="card">
                                        <div className="card-body p-30">
                                            <h3>Información del Ticket:</h3>
                                            <table className="table text-white text-left table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="text-white"><h5>Attr</h5></th>
                                                        <th className="text-white"><h5>Value</h5></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><b>idFranchise</b></td>
                                                        <td>{nameContract.idFranchise}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>amountUsd</b></td>
                                                        <td>{nameContract.amountUsd}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>amountStake</b></td>
                                                        <td>{nameContract.amountStake}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>limitByFranchise</b></td>
                                                        <td>{nameContract.limitByFranchise}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>limitByUser</b></td>
                                                        <td>{nameContract.limitByUser}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>activate</b></td>
                                                        <td>{nameContract.activate}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <Button className="btn btn-rent-invert" id="getdatacontract" onClick={handleValue}>Get Ticket</Button>
                                            {buscadorstatus}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Layout >
    );
};

